<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'ValidatorClient'
};
</script>

<style lang="scss">
#validator-client {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
