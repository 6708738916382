import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters.js';
// Модули
import app from './modules/app.js';
import errorLog from './modules/errorLog.js';
import user from './modules/user.js';
import ticket from './modules/ticket.js';
// Тесты
import initVuexTestMethods from '@/common/store/tests';

Vue.use(Vuex);

/**
 * @type {Store}
 */
const store = new Vuex.Store({
  modules: {
    app,
    user,
    ticket,
    errorLog
  },
  getters
});

initVuexTestMethods(store);

export default store;
