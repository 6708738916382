import * as API from '@/helpers/request';

/**
 * Получение информации об одном билете
 * В ответе будет информация о том, валидный ли он тут, если нет - о причине.
 * Данный вызов НЕ помечает билет использованным (пока что).
 *
 * @param number
 * @param locationId
 * @param hallId
 */
export async function getTicket(number, locationId, hallId) {
  return API.request.post('validator/badge/validate', {number, location_id: locationId, hall_id: hallId});
}

/**
 * Отправляем использованные билеты
 *
 * @param tickets
 */
export async function setUsed(tickets) {
  return API.request.post('validator/use/index', tickets);
}

/**
 * Информирование об online статусе
 *
 * @param data
 */
export async function ping(data) {
  return API.request.post('validator/main/ping', data);
}

/**
 * Получить фото владельца
 *
 * @param {number} id
 * @returns {Promise<any>}
 */
export async function getPersonPhoto(id) {
  return API.request.post('validator/tickets/person-photo', {ticket_person_id: id});
}