<template>
	<v-select ref="vSelect"
	          height="auto"
	          v-bind:value="emittedValue"
	          :options="options"
	          :filterable="false"
	          :clearable="clearable"
	          :placeholder="placeholder"
	          :selectable="selectableFunc"
	          :disabled="disabled"
	          :label="labelAttr"
	          :multiple="multiple"
	          :deselectFromDropdown="true"
	          :closeOnSelect="closeOnSelect"
	          :clearSearchOnBlur="() => !allowCreate"
	          :clearSearchOnSelect="!allowCreate"
	          class="form-control"
	          :class="{'select-editable': allowCreate}"
	          :reduce="option => parseValue(option[valueAttr])"
	          :components="{Deselect}"
	          :append-to-body="appendToBody"
	          @input="onInput"
	          @search="onSearch"
	          @search:focus="onFocus"
	          @search:blur="onBlur"
	          @option:selected="onSelected"
	          @option:deselected="onDeselected">
		<template #no-options="{ search, searching, loading }">
			<slot name="no-options" v-bind="{ search, searching, loading }">
				<template v-if="searching">
					Список пуст
				</template>

				<template v-if="!search && !searching">
					Начните вводить название
				</template>
			</slot>
		</template>
		<template #option="option">
			<slot name="option" v-bind="option">
				<div v-if="option.groupTitle"><b>{{ option.groupTitle }}</b></div>
				<div v-if="option[labelAttr]">{{ option[labelAttr] }}</div>
			</slot>
		</template>
		<template #selected-option="option">
			<slot name="selected-option" v-bind="option">
				<div style="overflow:hidden;text-overflow:ellipsis;">
					{{ option[labelAttr] }}
				</div>
			</slot>
		</template>
		<template #search="{attributes, events}">
			<input :name="name" v-bind="attributes" v-on="events" class="vs__search"/>
		</template>
	</v-select>
</template>

<script>
	import _ from 'lodash';

	export default {
		name          : 'SelectRemote',
		componentName : 'SelectRemote',
		props         : {
			value      : {
				required : true
			},
			listMethod : Function,
			query      : Object,
			/**
			 * Реляция
			 */
			join           : {
				type : [
					String,
					Array
				]
			},
			placeholder    : String,
			keyAttr        : {
				type    : String,
				default : 'id'
			},
			valueAttr      : {
				type    : String,
				default : 'id'
			},
			labelAttr      : {
				type    : String,
				default : 'title'
			},
			useGrouping    : {
				type    : Boolean,
				default : false
			},
			groupLabelAttr : {
				type : [
					String,
					Number
				]
			},
			/**
			 * Выводить кнопку очистки значения
			 */
			clearable  : {
				type    : Boolean,
				default : true
			},
			selectable : {
				type : Function,
				/**
				 * @param {Object|String} option
				 * @return {boolean}
				 */
				default : option => true
			},
			/**
			 * Отключен
			 */
			disabled : {
				type    : Boolean,
				default : false
			},
			/**
			 * Выбрать элемент, если он один
			 */
			selectIfOne : {
				type    : Boolean,
				default : false
			},
			/**
			 * Лимит выводимых записей <цифра> или 'all'
			 */
			limit : [
				Number,
				String
			],
			/**
			 * Значение атрибута name в input
			 */
			name : String,
			/**
			 * Множественный выбор значений
			 */
			multiple     : Boolean,
			firstLoading : {
				type    : Boolean,
				default : true
			},
			/**
			 * Вставка блока выбора в тэг body
			 */
			appendToBody : {
				type    : Boolean,
				default : false
			},
			/**
			 * Вставить опции в начале списка
			 */
			prependItems : {
				type : Array
			},
			/**
			 * Вставить опции в концк списка
			 */
			appendItems : {
				type : Array
			},
			/**
			 * Исключение элементов из текущего списка без перезагрузки списка
			 * Дальнешие запросы будут включать в себя эти исключения
			 */
			immediateExclude : Object,
			/**
			 * Поиск всех записей, даже не активных
			 */
			withNoActive : Boolean,
			/**
			 * Создавать новый элемент, если он не был найден
			 */
			allowCreate : {
				type    : Boolean,
				default : false
			}
		},

		data() {
			return {
				emittedValue      : '',
				options           : [],
				selectedOption    : null,
				groupLabel        : '',
				firstLoadingLocal : this.firstLoading,
				selectableFunc    : () => true,
				Deselect          : {
					render : createElement => createElement('div', { class : 'vs__deselect__custom' }, [
						createElement('font-awesome-icon', {
							class : 'vs__deselect_icon__fill', props : { icon : 'fa-solid fa-circle-xmark' }
						}),
						createElement('font-awesome-icon', {
							class : 'vs__deselect_icon__nofill', props : { icon : 'fa-regular fa-circle-xmark' }
						})
					])
				},
				/**
				 * Специальный метод поиска для включения задержки между вводом строки поиска,
				 * Почему в data, а не в methods? По той причине, что если расположить несколько таких компонентов на одной странице,
				 * то _.debounce для них всех будет одним и тем же (неуникальным).
				 * А расположив в data - он уникален для каждого компонента *facepalm*
				 */
				search : _.debounce(async (loading, query, selectIfOne, vm) => {
					await vm.getItemsList(query, selectIfOne);
					loading(false);

					vm.firstLoadingLocal = false;
				}, 350)
			};
		},
		computed : {
			closeOnSelect() {
				return !this.multiple;
			},
			/**
			 * Значение не пустое
			 *
			 * @returns {*|false|boolean}
			 */
			isValueNotEmpty() {
				return (!this.multiple && this.value) || (this.multiple && this.value && !this._.isEmpty(this.value));
			}
		},
		watch    : {
			immediateExclude(val, oldVal) {
				if (val && !this._.isEqual(val, oldVal)) {
					if (!this.useGrouping) {
						let newOptions = [];
						this._.each(this.options, item => {
							let exist = false;

							this._.each(val, (exclude, prop) => {
								let exVal = Array.isArray(exclude) ? exclude : [exclude];
								if (item.hasOwnProperty(prop) && exVal.includes(item[prop])) {
									exist = true;
								}
							});

							if (!exist) {
								newOptions.push(this._.clone(item));
							}
						});

						this.$set(this, 'options', newOptions);
					}
				}
			}
		},
		async mounted() {
			// Если установлено значение, например ID, то загружаем список для получения названия
			if (this.isValueNotEmpty) {
				let params = {};

				params[this.valueAttr] = this.value;
				await this.onSearch(params, null, false, true);
			}
			else {
				let options = [];

				if (this.prependItems && this.prependItems.length) {
					options = this.prependItems.concat(options);
				}

				if (this.appendItems && this.appendItems.length) {
					options = options.concat(this.appendItems);
				}

				this.$set(this, 'options', options);
			}

			this.emittedValue = this.parseValue(this.value);
			if (this.value && this.firstLoadingLocal && this.allowCreate) {
				this.$refs.vSelect.search = this.value;
				this.$nextTick(() => {
					this.$refs.vSelect.open = false;
				});
			}


			this.$watch('value', (val, old) => {
				this.emittedValue = this.parseValue(val);

				if (val && this.firstLoadingLocal) {
					let params = {};

					params[this.valueAttr] = val;

					if (this.allowCreate) {
						this.$refs.vSelect.search = val;
						this.$nextTick(() => {
							this.$refs.vSelect.open = false;
						});
					}

					this.onSearch(params);
				}
			});

			this.$watch('query', (val, old) => {
				if (this._.isEqual(val, old)) {
					return;
				}

				this.firstLoadingLocal = true;
			});

			this.$watch('join', (val, old) => {
				if (this._.isEqual(val, old)) {
					return;
				}

				this.firstLoadingLocal = true;
			});

			this.$watch('limit', () => {
				this.firstLoadingLocal = true;
			});

			this.$watch('prependItems', (val, old) => {
				if (this._.isEqual(val, old)) {
					return;
				}

				this.firstLoadingLocal = true;
			});

			this.$watch('appendItems', (val, old) => {
				if (this._.isEqual(val, old)) {
					return;
				}

				this.firstLoadingLocal = true;
			});
		},
		methods : {
			/**
			 * Получение списка элементов
			 *
			 * @param query
			 * @param selectIfOne
			 * @returns {Promise<void>}
			 */
			async getItemsList(query, selectIfOne) {
				query = !this._.isObject(query) ? { query } : query;

				if (!_.isEmpty(this.query)) {
					query['condition'] = this.query;
				}

				if (!_.isEmpty(this.join)) {
					query['join'] = this.join;
				}

				if (this.limit) {
					query['limit'] = this.limit;
				}

				if (this.withNoActive) {
					query['with_no_active'] = true;
				}

				if (!this._.isEmpty(this.immediateExclude)) {
					if (this._.isEmpty(query['condition'])) {
						query['condition'] = {};
					}

					if (this._.isEmpty(query['condition']['exclude'])) {
						query['condition']['exclude'] = {};
					}

					query['condition']['exclude'] = this._.merge(query['condition']['exclude'], this.immediateExclude);
				}

				let options = await this.listMethod(query);

				if (this.prependItems && this.prependItems.length) {
					options = this.prependItems.concat(options);
				}

				if (this.appendItems && this.appendItems.length) {
					options = options.concat(this.appendItems);
				}

				// При первой загрузке нужно заполнить selectedOption
				if (this.firstLoadingLocal && this.isValueNotEmpty) {
					let selectedOptions = this._.filter(options, (option) => {
						if (this.multiple) {
							return this.value.includes(option[this.valueAttr]);
						}

						return option[this.valueAttr] == this.value;
					});
					this.$set(this, 'selectedOption', selectedOptions);
				}

				// Если разрешено создание при вводе и строка не пуста
				if (this.allowCreate && query['query']) {
					let optionExists = false;
					// Сначала ищем такие же пункты выбора, чтобы не было дублей
					this._.each(options, obj => {
						if (obj[this.labelAttr] === query['query']) {
							optionExists = true;

							return false;
						}
					});

					if (!optionExists) {
						let newObj             = {};
						newObj[this.valueAttr] = query['query'];
						newObj[this.labelAttr] = query['query'];
						options                = [newObj].concat(options);

						if (!this.firstLoadingLocal && options.length === 1) {
							this.onInput(query['query']);
							this.onSelected(newObj);
							this.$refs.vSelect.open = false;
						}
					}
				}

				// Группировка опций и создание нового массива с выводом названий групп
				if (this.useGrouping) {
					let groups = [];

					_.each(options, obj => {
						if (obj[this.groupLabelAttr]) {
							const groupTitle = obj[this.groupLabelAttr];

							if (typeof groups[groupTitle] === 'undefined') {
								groups[groupTitle] = [];
							}

							groups[groupTitle].push(obj);
						}
						else {
							const emptyGroupName = 'Нет группы';

							if (typeof groups[emptyGroupName] === 'undefined') {
								groups[emptyGroupName] = [];
							}
							groups[emptyGroupName].push(obj);
						}
					});

					let groupOptions      = [],
					    currentGroupTitle = '';

					for (const [groupTitle, objects] of Object.entries(groups)) {
						if (currentGroupTitle !== groupTitle) {
							groupOptions.push({
								'groupTitle'     : groupTitle,
								[this.labelAttr] : '' // FIXME: другого решения не нашел, пустой this.labelAttr нужен, чтобы не возникал варнинг [vue-select warn]: Label key does not exist in options object
							});
						}

						objects.forEach(function (obj) {
							groupOptions.push(obj);
						});
					}

					options             = groupOptions;
					this.selectableFunc = (option) => !option.groupTitle;
				}

				// Добавление выбранных опций в загружаемый список, иначе уже выбранные превращаются в тыкву
				if (this.multiple && this.selectedOption !== null && this.value && this.selectedOption) {
					let unshifts = [];
					_.each(this.selectedOption, selectedObj => {
						const index = options.findIndex(optionObj => {
							return optionObj[this.valueAttr] === selectedObj[this.valueAttr];
						});

						if (index === -1) {
							unshifts.push(selectedObj);
						}
					});

					if (unshifts.length > 0) {
						unshifts.forEach(function (unshiftObj) {
							options.push(unshiftObj);
						});
					}
				}

				if (!this.multiple && !query.query && this.value && this.selectedOption) {
					let unshift = true;
					_.each(options, obj => {
						if (obj[this.valueAttr] == this.selectedOption[this.valueAttr]) {
							unshift = false;
						}
					});

					if (unshift) {
						options.unshift(this.selectedOption);
					}
				}

				this.$set(this, 'options', options);

				if (selectIfOne && !this.value && this.options.length === 1) {
					this.onInput(this.options[0][this.valueAttr]);
					this.onSelected(this.options[0]);
				}
			},
			/**
			 * Поиск который запускается по событию из компонента
			 *
			 * @param query
			 * @param loading
			 * @param selectIfOne
			 * @returns {Promise<void>}
			 */
			async onSearch(query, loading, selectIfOne) {
				if (!loading) {
					loading = this.toggleLoading;
				}

				if (!query && this.value && this.selectedOption) {
					this.$set(this, 'options', this.multiple ? this.selectedOption : [this.selectedOption]);
				}

				loading(true);
				await this.search(loading, query, selectIfOne, this);
			},
			/**
			 * Событие ввода для передачи значения дальше (как v-model)
			 *
			 * @param $event
			 */
			onInput($event) {
				this.emittedValue = $event;
				this.$emit('input', $event);
			},
			/**
			 * Метод, который срабатывает при событии выбора элемента
			 * Сохраняет весь элемент в переменную.
			 * Так как при следующем вводе строки сбрасывается список элементов и выбранное значение теряет название, то этот элемент будет добавлять всегда
			 *
			 * @param $event
			 */
			onSelected($event) {
				this.selectedOption = this._.cloneDeep($event);

				if (this.allowCreate) {
					this.$refs.vSelect.search = this.selectedOption[this.labelAttr];
				}

				this.$nextTick(() => {
					this.$emit('change', this.selectedOption);
				});
			},
			/**
			 * Метод, который срабатывает при очистки значения
			 *
			 * @param $event
			 */
			onDeselected($event) {
				this.$nextTick(() => {
					this.$emit('change', $event);
				});
			},
			/**
			 * Метод срабатывает при открытии списка. Если это первое открытие, то подгружаем начальный список элементов
			 *
			 * @returns {Promise<void>}
			 */
			async onFocus() {
				if (this.firstLoadingLocal) {
					await this.onSearch();
				}
			},
			/**
			 * Метод срабатывает при закрытии списка.
			 *
			 * @returns {Promise<void>}
			 */
			onBlur() {
				//this.$refs.vSelect.open = true;
			},
			/**
			 * Включение/Выключение иконки загрузки списка
			 *
			 * @param state
			 */
			toggleLoading(state) {
				this.$refs.vSelect && this.$refs.vSelect.toggleLoading(state);
			},
			/**
			 * Сброс компонента в первоначальное состояние
			 */
			reset(withoutValue) {
				this.toggleLoading(false);
				this.firstLoadingLocal = true;

				let options        = [];
				let selectedOption = null;
				let value          = null;

				if (withoutValue) {
					if (this.selectedOption) {
						selectedOption = this._.cloneDeep(this.selectedOption);
						options.push(selectedOption);
					}

					value = this.parseValue(this.value);
				}

				this.emittedValue = value;
				this.$set(this, 'options', options);
				this.$set(this, 'selectedOption', selectedOption);
				this.$emit('input', value);
			},

			parseValue(value) {
				if (value === null) {
					return null;
				}

				if (this.multiple) {
					return value;
				}

				return /^-?\d+$/.test(value) ? +value : value;
			}
		}
	};
</script>

<style lang="scss">
	.v-select.vs--multiple {
		height: auto !important;
	}

	.v-select {
		.vs__dropdown-option--selected {
			font-weight: bold;
		}

		&.form-control {
			&.vs--disabled {
				background-color: $input-disabled-bg;
				opacity: 1;

				.vs__actions,
				.vs__open-indicator,
				input {
					background-color: $input-disabled-bg;
				}
			}

			.vs__dropdown-toggle {
				padding: 0;
				border: none;
				border-radius: 0;
				justify-content: space-between;

				.vs__selected-options {
					min-width: 0;
					padding: 0;

					.vs__search {
						line-height: inherit;
						border: none;
						margin: 0;
						padding: 0;
					}
				}

				.vs__actions {
					padding: 0 0 0 3px;
				}
			}

			.vs__selected {
				line-height: 1.1;
				color: $input-color;
			}

			&.vs--single.vs--searchable {
				height: auto;
			}

			&.vs--multiple {
				.vs__selected {
					margin: 2px 0 2px 6px;
					padding: 0 5px;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					max-width: 100%;
					justify-content: space-between;
					border-color: $warning;
					font-weight: bold;
					background-color: #f4f4f5;
					display: flex;
					align-items: center;
					font-size: 12px;
					border-radius: 0;
					max-width: 95%;

					.vs__deselect__custom {
						cursor: pointer;
						color: #909399;

						.vs__deselect_icon__fill {
							display: none;
						}

						.vs__deselect_icon__nofill {
							display: block;
						}
					}

					.vs__deselect__custom:hover {
						.vs__deselect_icon__fill {
							display: block;
						}

						.vs__deselect_icon__nofill {
							display: none;
						}
					}
				}
			}

			.vs__dropdown-menu {
				top: calc(100% + 1px);
				left: -1px;
				width: auto;
				min-width: 100%
			}

			.input-group-sm & {
				.vs__actions {
					.vs__spinner {
						width: 4em;
						height: 4em;
					}
				}
			}
		}

		&.select-editable {
			.vs__search {
				opacity: 1 !important;
			}
		}
	}

	.vs__dropdown-menu {
		z-index: 9999;
	}
</style>