<template>
	<b-button :disabled="clicked || disabled"
	          class="b-once-button"
	          v-on="listeners"
	          :variant="variant"
	          :size="size"
	          :block="block">
		<slot v-if="!clicked || (!clickedText && !showLoader)"></slot>
		<template v-else-if="clickedText && !showLoader">
			{{ clickedText }}
		</template>

		<loader v-if="clicked && showLoader"
		        size="1em"
		        line-width="8"
		        min-height="0"
		        :with-background="false"/>
	</b-button>
</template>

<script>
	import Loader from '@/common/components/Loader';

	export default {
		name       : 'bOnceButton',
		components : {
			Loader
		},
		props      : {
			/**
			 * Текст при "выполнении действия"
			 */
			clickedText : String,
			/**
			 * Вариант кнопки (bootstrap-свойство)
			 */
			variant : String,
			/**
			 * Размер кнопки (bootstrap-свойство)
			 */
			size : String,
			/**
			 * Отрисовка кнопки (bootstrap-свойство)
			 */
			block : {
				type    : Boolean,
				default : false
			},
			/**
			 * Отключение кнопки
			 */
			disabled : {
				type    : Boolean,
				default : false
			},
			/**
			 * Показать лоадер
			 */
			showLoader : {
				type    : Boolean,
				default : false
			}
		},
		data() {
			return {
				/**
				 * Флаг, означающий, что кнопка в нажатом состоянии
				 */
				clicked : false
			};
		},
		computed : {
			listeners() {
				// spread the listeners passed from the parent, but override the click one
				return {
					...this.$listeners,
					click : this.handleClick
				};
			}
		},
		methods  : {
			/**
			 * Обработка клика по кнопке
			 * @returns {Promise<void>}
			 */
			async handleClick(e) {
				if (this.clicked || this.disabled) {
					return;
				}

				this.$set(this, 'clicked', true);

				try {
					await this.$listeners.click(e);
				}
				catch (e) {
				}

				this.$set(this, 'clicked', false);
			}
		}
	};
</script>

<style lang="scss">
	.b-once-button {
		.hwm-loader {
			display: inline-flex;

			.loading-spinner {
				.path {
					stroke: $gray-700;
				}
			}
		}
	}
</style>