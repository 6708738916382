<template>
	<div class="cascader"
	     ref="cascader">
		<div v-if="!multiple"
		     class="form-control cascader-single-input"
		     :class="inputClasses">

			<input :value="inputValue"
			       :placeholder="placeholder"
			       :disabled="disabled"
			       readonly
			       @click="showDropdown()">


			<font-awesome-icon v-if="canClear"
			                   @click="clear()"
			                   :icon="['far', 'circle-xmark']"/>
		</div>

		<div @click="showDropdown()">
			<b-tags v-if="multiple"
			        :value="inputValue"
			        :placeholder="placeholder || ''"
			        :size="size"
			        readonly
			        :disabled="disabled">
				<template v-slot="{ tags, disabled, removeTag }">
					<ul v-if="tags.length > 0"
					    class="b-form-tags-list">
						<li v-for="tag in tags"
						    :key="tag"
						    :title="tag"
						    class="b-form-tag badge badge-input-tag">
						<span class="b-form-tag-content flex-grow-1 text-truncate">
							{{ parseTag(tag)[labelAttr] }}
						</span>

							<div class="b-form-tag-remove">
								<font-awesome-icon v-if="!disabled"
								                   @click="clickRemoveTag(tag, removeTag)"
								                   :icon="['far', 'circle-xmark']"/>
							</div>
						</li>
					</ul>
				</template>
			</b-tags>
		</div>


		<div class="cascader-dropdown"
		     ref="cascaderDropdown"
		     :class="{ 'show-dropdown' : isShowDropdown}"
		     :style="{ left: `-${dropdownOffset}px`}">

			<template v-if="list && list.length">
				<cascader-menu v-for="(menu, index) in menus"
				               :key="index"
				               :menu-id="index"
				               v-model="emittedValue"
				               :active-ids="activeIds"
				               :multiple="multiple"
				               :label-attr="labelAttr"
				               :value-attr="valueAttr"
				               :children-attr="childrenAttr"
				               :options="menu">
					<template v-slot="item">
						<slot v-bind="item"/>
					</template>
				</cascader-menu>
			</template>
		</div>
	</div>
</template>

<script>
	import CascaderMenu from './menu.vue';

	export default {
		name       : 'Cascader',
		components : { CascaderMenu },
		provide() {
			return {
				cascader : this
			};
		},
		inject : {
			adminForm : {
				default : false
			}
		},
		props  : {
			value : [
				String,
				Number,
				Array
			],
			/**
			 * Пункты селектора, если не указан listMethod
			 */
			options : Array,
			/**
			 * Если указан, то пункты будут браться по запросу
			 */
			listMethod : Function,
			/**
			 * Дополнительные параметры зароса
			 */
			query : Object,
			/**
			 * Поиск всех записей, даже не активных
			 */
			withNoActive : Boolean,
			/**
			 * Мульти выбор
			 */
			multiple : {
				type    : Boolean,
				default : false
			},
			/**
			 * Атрибут для названия
			 */
			labelAttr : {
				type    : String,
				default : 'title'
			},
			/**
			 * Атрибут для значения
			 */
			valueAttr : {
				type    : String,
				default : 'id'
			},
			/**
			 * Атрибут для списка дочерних элементов
			 */
			childrenAttr : {
				type    : String,
				default : 'children'
			},
			/**
			 * placeholder для полей ввода
			 */
			placeholder : String,
			/**
			 * Отключение поля
			 */
			disabled : {
				type    : Boolean,
				default : false
			},
			/**
			 * Можно ли очищать
			 */
			clearable : {
				type    : Boolean,
				default : true
			},
			/**
			 * Размер
			 */
			size : String
		},

		data() {
			return {
				isShowDropdown : false,
				list           : [],
				mappedList     : {},
				menus          : [],
				allTreeItems   : [],
				emittedValue   : null,
				dropdownOffset : 0
			};
		},

		computed : {
			/**
			 * Активные ID для подсветки.
			 * Активными считаются все родители выбранных значений и само значение
			 *
			 * @returns {*}
			 */
			activeIds() {
				return this._.uniq(this._.map(this.allTreeItems, this.valueAttr));
			},
			/**
			 * Видимое значение для полей ввода
			 *
			 * @returns {*|*[]}
			 */
			inputValue() {
				if (!this.multiple) {
					return this._.map(this.allTreeItems, this.labelAttr).join(' / ');
				}

				if (!this.emittedValue || !this.emittedValue.length) {
					return [];
				}

				if (!this.mappedList || this._.isEmpty(this.mappedList)) {
					return [];
				}

				let array = [];
				for (let id of this.emittedValue) {
					array.push(this.mappedList[id]);
				}

				return array;
			},
			/**
			 * Можно ли очистить значение. Показ крестика
			 *
			 * @returns {false|string|number|*[]}
			 */
			canClear() {
				return this.clearable && this.value;
			},
			/**
			 * Классы
			 *
			 * @returns {*[]}
			 */
			inputClasses() {
				let arr = [];
				if (this.clearable) {
					arr.push('clearable');
				}

				if (this.size) {
					arr.push(`form-control-${this.size}`);
				}

				if (this.disabled) {
					arr.push('disabled');
				}

				return arr;
			}
		},

		watch : {
			value(val, old) {
				if (
					val == old ||
					(val === null && old === []) ||
					(old === null && val === [])
				) {
					return;
				}

				if (val === null && old && (!Array.isArray(old) || old.length)) {
					this.clear();

					return;
				}

				let value;
				if (val === null || val === undefined || (Array.isArray(val) && !val.length)) {
					value = this.multiple ? [] : null;
				}
				else {
					value = this.multiple && !Array.isArray(val) ? [val] : val;
				}

				this.$set(this, 'emittedValue', value);
			},

			emittedValue(val) {
				this.allTreeItems = this.getAllTreeItems(val, this.list);

				let value = val;
				if (!value || (this.multiple && !value.length)) {
					value = null;
				}

				this.$emit('input', value);
				this.$emit('change', value);
			}
		},

		async mounted() {
			document.addEventListener('click', this.hideDropdownHandler);

			if (this.adminForm) {
				if (this.adminForm.loaded) {
					this.firstLoad();
				}
				else {
					this.adminForm.$once('loaded', () => {
						this.firstLoad();
					});
				}
			}
			else {
				this.$nextTick(() => {
					this.firstLoad();
				});
			}
		},

		methods : {
			/**
			 * Инициализация
			 */
			firstLoad() {
				if (!this.emittedValue) {
					if (this.multiple) {
						this.emittedValue = this.value && Array.isArray(this.value) ? this.value : [];
					}
					else {
						this.emittedValue = this.value;
					}
				}

				this.getItemsList();
			},
			/**
			 * Получение и обработка списка
			 *
			 * @returns {Promise<void>}
			 */
			async getItemsList() {
				let query = {};
				if (!this._.isEmpty(this.query)) {
					query['condition'] = this.query;
				}

				if (this.limit) {
					query['limit'] = this.limit;
				}

				if (this.withNoActive) {
					query['with_no_active'] = true;
				}

				let list          = this.listMethod ? await this.listMethod(query) : this.options;
				this.allTreeItems = this.getAllTreeItems(this.value, list);
				this.mappedList   = this.getMappedList(list);
				this.list         = list;
				this.menus        = [list];
			},
			/**
			 * Показ выпадающего меню
			 */
			showDropdown() {
				if (this.disabled || this.isShowDropdown) {
					return;
				}

				this.isShowDropdown = true;
				this.setDropdownOffset();
			},
			/**
			 * Получение всех родителей выбранных значений + выбранное значение
			 * Необходимо для формирования текста для поля ввода. Название значения
			 *
			 * @param ids
			 * @param options
			 * @param allTreeItems
			 * @returns {{length}|[]|*[]|*|*[]}
			 */
			getAllTreeItems(ids, options, allTreeItems = []) {
				if (
					!ids ||
					(Array.isArray(ids) && !ids.length) ||
					!options ||
					!options.length
				) {
					return [];
				}

				ids = Array.isArray(ids) ? ids : [ids];

				for (let item of options) {
					if (ids.includes(item.id)) {
						return allTreeItems.concat(item);
					}

					const found = this.getAllTreeItems(ids, item.children, allTreeItems.concat(item));
					if (found && found.length) {
						return found;
					}
				}

				return [];
			},
			/**
			 * Преобразовываем список дерева в линейный.
			 * Необходимо для мультивыбора, чтобы получить значения
			 *
			 * @param list
			 * @returns {{}}
			 */
			getMappedList(list) {
				if (!this.multiple) {
					return {};
				}

				if (!list || !list.length) {
					return {};
				}

				let mappedList = {};
				for (let item of list) {
					mappedList[item.id] = item;

					const found = this.getMappedList(item.children);
					if (!this._.isEmpty(found)) {
						mappedList = Object.assign(mappedList, found);
					}
				}

				return mappedList;
			},
			/**
			 * Добавление меню. Другими словами раскрытие списка при выборе родителя
			 * @param id
			 * @param menu
			 */
			addMenu(id, menu) {
				if (this.menus.length - 1 !== id) {
					this.menus.splice(id + 1);

				}

				this.menus.push(menu);
				this.setDropdownOffset();
			},
			/**
			 * Позиционирование выпадающего списка
			 */
			setDropdownOffset() {
				this.$nextTick(() => {
					let dropdownWidth    = 0;
					// Все блоки со списками
					let menuEls          = this.$refs.cascaderDropdown.querySelectorAll('.cascader-menu');
					// Изначальная позиция меню на экране
					let cascaderPosition = this.$refs.cascader.getBoundingClientRect().left || 0;
					// Ширина экрана
					let screenWidth      = document.body.clientWidth;
					// Получаем общую ширину выпадающего меню из блоков со списком. Иначе никак, родитель имеет фикс ширину и не меняется (хз почему)
					menuEls.forEach(element => {
						dropdownWidth += element.offsetWidth;
					});

					// Помещается ли меню на экран, а точнее последний бокс со списком
					let screenBoxSize = screenWidth - cascaderPosition;
					if (dropdownWidth > screenBoxSize) {
						// Если нет, то делаем смещение влево для нормального отображения последнего бокса
						this.dropdownOffset = dropdownWidth - screenBoxSize + 5;
					}
					else {
						this.dropdownOffset = 0;
					}
				});
			},
			/**
			 * Очистка значения
			 */
			clear() {
				this.emittedValue = this.multiple ? [] : null;
				this.allTreeItems = [];
				this.menus        = [this.list];
			},
			/**
			 * Клик по области на экране, чтобы закрыть выпадающий список
			 *
			 * @param event
			 */
			hideDropdownHandler(event) {
				let isIn = false;
				if (this.$refs.cascader) {
					isIn = this.$refs.cascader === event.target || this.$refs.cascader.contains(event.target);
				}

				if (!isIn) {
					this.isShowDropdown = false;
				}
			},
			/**
			 * Парсим тэг. Так как ребята из bootstrap не замутили возможность работы с объектом.
			 * Объект они переводят в строку
			 *
			 * @param tag
			 * @returns {{}}
			 */
			parseTag(tag) {
				let obj = {};
				try {
					obj = JSON.parse(tag);
				}
				catch (e) {

				}

				return obj;
			},
			/**
			 * удаление тэга при мультивыборе
			 * @param tag
			 */
			clickRemoveTag(tag) {
				let item  = this.parseTag(tag);
				let index = this.emittedValue.indexOf(item[this.valueAttr]);
				this.emittedValue.splice(index, 1);
			}
		},
		/**
		 * Удаляем события
		 */
		beforeDestroy() {
			document.removeEventListener('click', this.hideDropdownHandler);
		}
	};
</script>

<style lang="scss">
	.cascader {
		position: relative;

		.cascader-dropdown {
			display: none;
			box-sizing: border-box;
			position: absolute;
			top: calc(100% - 1px);
			left: 0;
			z-index: 1000;
			margin: 0;
			height: 215px;
			background: #fff;

			&.show-dropdown {
				display: flex;
			}

			.cascader-menu {
				text-align: left;
				list-style: none;
				background: #fff;
				font-size: 14px;
				margin: 0;
				height: 100%;
				overflow-y: auto;
				min-width: 180px;
				flex-shrink: 0;
				padding: 5px 0;
				border: 1px solid rgba(60, 60, 60, 0.26);

				&:not(:first-child) {
					border-left: none;
				}

				&:not(:last-child) {
					border-right: 1px solid rgba(60, 60, 60, 0.26);
				}

				.cascader-menu-item {
					display: flex;
					position: relative;
					align-items: center;
					padding: 0 30px 0 10px;
					height: 34px;
					line-height: 34px;
					outline: none;
					cursor: pointer;

					&.is-active {
						color: $primary;
					}

					&.is-selected {
						background-color: #e6e6e6;
					}

					&:hover {
						background-color: #d5eaff;
					}

					.control-item {
						.custom-control-label {
							&:before,
							&:after {
								cursor: pointer;
							}
						}
					}

					.arrow-right {
						position: absolute;
						right: 10px;
					}
				}
			}
		}

		.cascader-single-input,
		.b-form-tags {
			width: 100%;

			&.form-control {
				min-height: calc(1.5em + 0.75rem + 2px);
			}

			&.form-control-lg {
				min-height: calc(1.5em + 1rem + 2px);
			}

			&.form-control-sm {
				min-height: calc(1.5em + 0.5rem + 2px);
			}
		}


		.form-control {
			display: flex;
			align-items: center;

			&[readonly]:not(.disabled):not([disabled]) {
				background-color: #fff;
			}

			&.disabled {
				background-color: #e9ecef;
				opacity: 1;

				input {
					background: inherit;
				}
			}

			&.cascader-single-input {
				.fa-circle-xmark {
					margin-left: 10px;
				}
			}

			.fa-circle-xmark {
				color: inherit;

				&:hover {
					cursor: pointer;
					color: $warning;
				}
			}

			.b-form-tags-list {
				display: flex;
				padding-left: 0;
				list-style: none;
				margin-bottom: 0;
				align-items: center;
				flex-wrap: wrap;
				max-width: 100%;

				.b-form-tag {
					display: inline-flex;
					max-width: 100%;
					align-items: center;
					padding: 0.1em 0.3em;

					.b-form-tag-remove {
						display: flex;
						float: none;
						margin-left: 0.3rem;
					}
				}
			}

			input {
				width: 100%;
				padding: 0;
				border: none;
				outline: none;
				color: inherit;

				&[readonly]:not([disabled]) {
					background-color: #fff;
				}
			}
		}
	}
</style>
