import { request } from '@/helpers/request';

/**
 * Авторизация пользователя
 *
 * @param user
 * @param pass
 */
export async function login(user, pass) {
	return request.post('login', {
		email   : user,
		password: pass
	});
}

/**
 * Выход пользователя
 */
export async function logout() {
	return request.post('auth/logout');
}

/**
 * Получение информации о текущей сессии (имя пользователя, кассы и т.д.)
 */
export async function getLoggedInData() {
	return request.post('validator/main/logged-in-data');
}

/**
 * Запрос-проверка бэкенда. Возвращает имя инстанса и версию БС
 * @returns {Promise<any>}
 */
export async function checkBackend() {
	return request.get('client/check');
}