import * as validator                                 from '@/api/validator';
import Vue                                            from 'vue';
import { getLocalStorageParam, setLocalStorageParam } from '@/common/helpers/localStorage';

const app = {
	state     : {
		cache          : [],
		hash           : null,
		usedQueue      : [],
		usedQueueTimer : null,
		cacheTimer     : null
	},
	mutations : {
		SET_USED_TICKET    : (state, { tickets, readonly }) => {
			state.usedQueue = tickets;
			if (!readonly) {
				setLocalStorageParam('used_queue', state.usedQueue);
			}
		},
		ADD_USED_TICKET    : (state, { ticket, readonly }) => {
			state.usedQueue.push(ticket);
			if (!readonly) {
				setLocalStorageParam('used_queue', state.usedQueue);
			}
		},
		DELETE_USED_TICKET : (state, { ticket, readonly }) => {
			state.usedQueue.splice(state.usedQueue.indexOf(ticket), 1);
			if (!readonly) {
				setLocalStorageParam('used_queue', state.usedQueue);
			}
		},
		/**
		 * Установка таймера отправки очереди
		 *
		 * @param state
		 * @param timer
		 * @constructor
		 */
		SET_QUEUE_TIMER : (state, timer) => {
			state.usedQueueTimer = timer;
		},
		/**
		 * Отчистка таймера отправки очереди
		 *
		 * @param state
		 * @constructor
		 */
		CLEAR_QUEUE_TIMER : (state) => {
			if (state.usedQueueTimer) {
				window.clearTimeout(state.usedQueueTimer);
				state.usedQueueTimer = null;
			}
		}
	},
	actions   : {
		/**
		 * Валидация билета
		 * Ищет в кеше, если не находит - делает запрос на сервер.
		 *
		 * @param commit
		 * @param state
		 * @param getters
		 * @param number
		 * @returns {Promise}
		 */
		async validateTicket({ commit, state, getters }, number) {
			return validator.getTicket(number, getters.locationId, getters.hallId);
		},

		/**
		 *
		 * @param dispatch
		 * @param commit
		 * @param state
		 * @param getters
		 * @param ticket
		 */
		setTicketUsed({ dispatch, commit, state, getters }, ticket) {
			commit('ADD_USED_TICKET', { ticket, readonly : getters.loggedInData.readonly });

			if (!state.usedQueueTimer) {
				dispatch('processUsedQueue');
			}
		},

		/**
		 * Отправка использованных билетов на сервер
		 *
		 * @param dispatch
		 * @param commit
		 * @param state
		 * @param getters
		 * @returns {Promise<*>}
		 */
		async processUsedQueue({ dispatch, commit, state, getters }) {
			if (!state.usedQueue.length && !getters.loggedInData.readonly) {
				let usedQueue = getLocalStorageParam('used_queue');

				if (usedQueue) {
					commit('SET_USED_TICKET', { tickets : usedQueue, readonly : getters.loggedInData.readonly });
				}
			}

			if (state.usedQueue.length > 0) {
				let payload = [];

				Vue._.each(state.usedQueue, (ticket) => {
					payload.push({
						number            : ticket.number,
						location_id       : getters.locationId,
						hall_id           : getters.hallId,
						validation_log_id : ticket.validation_log_id
					});
				});

				try {
					let result = await validator.setUsed(payload);
					console.log('Got results', result);
					Vue._.each(result.processed, (number) => {
						let t = Vue._.find(state.usedQueue, (ticket) => {
							return ticket.number == number;
						});

						if (t) {
							commit('DELETE_USED_TICKET', { ticket : t, readonly : getters.loggedInData.readonly });
						}
						else {
							console.warn('Билет ' + number + ' не был обработан');
						}
					});

					commit('CLEAR_QUEUE_TIMER');
				}
				catch (e) {
					console.warn('Ошибка при отправке использованных билетов, будем пробовать потом', e);

					if (!state.usedQueueTimer) {
						commit('SET_QUEUE_TIMER', window.setInterval(() => {
							dispatch('processUsedQueue');
						}, 15000));
					}
				}

			}
			else {
				commit('CLEAR_QUEUE_TIMER');
			}

			if (state.usedQueueTimer && !getters.isLoggedIn) {
				commit('CLEAR_QUEUE_TIMER');
			}

			return Promise.resolve();
		}
	}
};

export default app;
