<template>
	<div class="main-menu-component" :class="{'active': menuVisible}">
		<div class="menu-button menu-button-open"
		     @click="activateMenu"
		     title="Показать меню">
			<b-icon-list />
		</div>
		<div class="menu-button menu-button-close"
		     @click="hideMenu"
		     title="Скрыть меню">
			<b-icon-x />
		</div>

		<div class="main-menu" :class="{'visible': menuVisible}">
			<h3>Валидатор</h3>
			<router-link class="menu-item" :to="{name: 'Main'}">Основной экран</router-link>
<!--			<router-link class="menu-item" :to="{name: 'SelectLocation'}">Выбрать локацию и зал</router-link>-->
<!--			<router-link class="menu-item" :to="{name: 'TicketList'}">Список бейджов</router-link>-->
			<div class="menu-item menu-item-spacer"></div>
			<div class="menu-item logout" @click="logout">Выйти</div>
		</div>

	</div>
</template>

<script>
	import { BIconList, BIconX } from 'bootstrap-vue';

	export default {
		name : 'Menu',
		components : { BIconList, BIconX },
		data() {
			return {
				menuVisible: false
			}
		},
		methods: {
			activateMenu() {
				this.menuVisible = true;
			},

			hideMenu() {
				this.menuVisible = false;
			},

			async logout() {
				await this.$store.dispatch('logout');
				window.location.reload();
				this.$forceUpdate();
			}
		},
		watch: {
			$route (to, from) {
				this.hideMenu();
			}
		}
	};
</script>

<style lang="scss">
	@import "@/styles/variables";

	.main-menu-component {
		.menu-button {
      color: #FFFFFF;
			z-index: 1000;
			transition: 0.3s ease opacity;
			font-size: $borderWidth - 8px;
			line-height: 1;
			position: fixed;
			top: 0;
			left: 0;
			width: $borderWidth;
			height: $borderWidth;
			padding: 4px;
			cursor: pointer;
		}
		.menu-button-close {
			pointer-events: none;
			opacity: 0;
		}

		&.active {
			.menu-button-close {
				pointer-events: initial;
				opacity: 1;
			}

			.menu-button-open {
				pointer-events: none;
				opacity: 0;
			}
		}
		.main-menu {
			$menuDesktopWidth: 350px;
			$menuMobileWidth: 100%;
			$desktopWidth: 768px;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 90;
			height: 100vh;
			width: 100%;
			transition: all 0.2s ease-in-out;
			opacity: 0;
			pointer-events: none;
			transform: translateX(-$menuMobileWidth);
			box-shadow: none;

			@media (min-width: $desktopWidth) {
				width: $menuDesktopWidth;
				transform: translateX(-$menuDesktopWidth);
				&.visible {
					box-shadow: 20px 0px 20px rgba(0,0,0,0.3);
				}
			}

			&.visible {
				pointer-events: initial;
				opacity: 1;
				transform: translateX(0);

			}

			background: #efefef;
			display: flex;
			flex-direction: column;

			justify-content: flex-start;
			align-items: flex-start;

			h3 {
				font-weight: bold;
				padding-top: 60px;
				padding-right: 15px;
				padding-left: 15px;
				padding-bottom: 15px;
				background: $hwmBrandColor;
				width: 100%;
				color: white;
			}
			.menu-item {
				padding: 5px 15px;
				color: black;
				cursor: pointer;
				font-size: 16px;
				text-decoration: none;

				&.logout {
					margin-bottom: 20px;
					color: #777;
				}
			}

			.menu-item-spacer {
				flex-grow: 1;
			}

			.close-menu {
				position: absolute;
				z-index: 1000;
				top: 5px;
				left: 11px;
				cursor: pointer;
				color: black;
				font-size: 40px;
				line-height: 40px;
			}
		}

	}

</style>