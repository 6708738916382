<template>
	<div class="hwm-loader"
	     :class="{
			transparent,
			'with-background' : withBackground,
			'in-block': inBlock,
			'full-screen': fullScreen
		}"
	     :style="{minHeight, maxHeight}">
		<div class="loading-spinner">
			<svg viewBox="25 25 50 50" class="circular" :style="{width: size, height: size}">
				<circle cx="50" cy="50" r="20" fill="none" class="path" :style="{strokeWidth: lineWidth}"></circle>
			</svg>
		</div>
	</div>
</template>

<script>
	export default {
		name          : 'Loader',
		componentName : 'Loader',
		props         : {
			size        : {
				type    : String,
				default : '42px'
			},
			lineWidth   : {
				type    : String,
				default : '2'
			},
			transparent : {
				type    : Boolean,
				default : false
			},
			withBackground : {
				type    : Boolean,
				default : true
			},
			inBlock     : {
				type    : Boolean,
				default : false
			},
			minHeight   : {
				type    : String,
				default : '8vh'
			},
			maxHeight   : String,
			/**
			 * Фиксированная позиция на весь экран
			 */
			fullScreen : {
				type    : Boolean,
				default : false
			},
		}
	};
</script>

<style lang="scss">
	$loader-background: #f8f8f8 !default;
	$loader-color: #007BFF !default;

	.hwm-loader {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1040;

		&.with-background {
			background: $loader-background;
		}

		&.full-screen {
			position: fixed;
			top:0;
			left: 0;
			width: 100%;
		}

		&.transparent {
			background-color: #ffffff85;
		}

		&.in-block {
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
		}

		.loading-spinner {
			text-align: center;
			line-height: 1;

			.circular {
				animation: loading-rotate 2s linear infinite;
			}

			.path {
				animation: loading-dash 1.5s ease-in-out infinite;
				stroke-dasharray: 90, 150;
				stroke-dashoffset: 0;
				stroke: $loader-color;
				stroke-linecap: round;
			}
		}
	}

	@keyframes loading-rotate {
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes loading-dash {
		0% {
			stroke-dasharray: 1, 200;
			stroke-dashoffset: 0;
		}
		50% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -40px;
		}
		100% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -120px;
		}
	}

</style>
