import axios, { AxiosInstance } from 'axios';
import store                    from '@/store';
import { removeAuth }           from '@/common/helpers/localStorage';

/**
 * Создание запроса
 *
 * @type {AxiosInstance}
 */
const request = axios.create({
	baseURL: process.env.VUE_APP_ADMIN_API_URL
});

/**
 * Настройка перед отправкой запроса
 */
request.interceptors.request.use(config => {
	if (store.getters.user && store.getters.token) {
		config.headers['USER-EMAIL'] = store.getters.user;
		config.headers['AUTH-TOKEN'] = store.getters.token;
	}

	config.headers['Content-Type'] = 'application/json; charset=UTF-8';

	let page = window.location.href;
	if (page) {
		config.headers['X-HWM-Page'] = page;
	}

	return config;
}, error => {
	// Do something with request error
	console.error(error); // for debug
	Promise.reject(error);
});

/**
 * Настройка действий после получения ответа
 */
request.interceptors.response.use(
	response => {
		return response.data;
	},
	error => {
		console.error('Ошибка запроса (response):', error);

		try {
			if (error.config.url !== 'login' && error.response.status === 401) {
				removeAuth();

				Promise.reject(error);

				//window.location.reload(true);
			}
			return Promise.reject(error);
		}
		catch (e) {
			return Promise.reject(error);
		}

	});

/**
 * Экспорт экземпляра axios
 */
export { request };
