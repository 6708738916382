<template>
	<div class="ticket-list-index inner-layout">
		<header class="header page-header long-text">Бейджы на сеанс</header>
		<div class="container">
			<!-- Зона "сверху": выбор мероприятия и сеанса. Просто селект, пока что -->
			<div class="product-select-wrapper">

				<b-form-group class="field-date" label="Дата:">
					<custom-input v-model="selectedDate" type="date" :clearable="false"/>
				</b-form-group>


				<b-form-group class="field-event" label="Событие:" :disabled="!selectedDate && !eventsReady">
					<b-form-select v-model="selectedEvent" :options="eventOptions"/>
				</b-form-group>


				<b-form-group class="field-time" label="Сеанс:" :disabled="!hasTime">
					<b-form-select v-model="selectedTime" :options="timeOptions"/>
					<b-once-button class="reload-button"
					               clicked-text="..."
					               variant="outline-secondary"
					               @click="loadTickets()">
						<b-icon-arrow-counterclockwise/>
					</b-once-button>
				</b-form-group>
			</div>
			<!-- Зона 2: фильтры-кнопки -->
			<div class="filters-wrapper"></div>

			<!-- Зона 3: сам список -->
			<div class="ticket-list-wrapper">
				<table class="table table-narrow" v-if="tickets && tickets.length > 0">
					<thead>
					<tr class="desktop">
						<th>#</th>
						<th>Имя</th>
						<th>Телефон</th>
						<th>E-mail</th>
						<th>Откуда</th>
						<th>Проход</th>
					</tr>
					</thead>
					<tbody>
					<tr class="ticket-line"
					    v-for="ticket in tickets"
					    :class="{'used': !!ticket.date_used, 'not-used': !ticket.date_used}">


						<td class="mobile">
							<div class="name" v-if="ticket.name">{{ ticket.name }}</div>
							<div class="name not-specified" v-else>(имя не указано)</div>
							<div class="number">{{ ticket.number }}</div>
							<div class="seller-name"
							     :class="'seller-module-' + ticket.sales_channel_module_id">{{ ticket.sales_channel_title }}
							</div>

						</td>
						<td class="mobile">
							<div class="phone" v-if="ticket.phone">
								<a :href="'tel:' + ticket.phone">+{{ ticket.phone }}</a></div>
							<div class="phone not-specified" v-else>(нет телефона)</div>
							<div class="email" v-if="ticket.email">{{ ticket.email }}</div>
							<div class="email not-specified" v-else>(нет email)</div>
							<div class="date-used" v-if="ticket.date_used">Вошел {{ ticket.date_used }}</div>
							<div class="date-used not-used" v-else>Ещё не пришел</div>
						</td>

						<td class="desktop order-number">{{ ticket.number }}</td>
						<td class="desktop name" v-if="ticket.name">{{ ticket.name }}</td>
						<td class="desktop name not-specified" v-else>(нет имени)</td>
						<td class="desktop phone" v-if="ticket.phone">
							<a :href="'tel:' + ticket.phone">+{{ ticket.phone }}</a></td>
						<td class="desktop phone not-specified" v-else>(нет телефона)</td>
						<td class="desktop email">{{ ticket.email }}</td>
						<td class="desktop seller-name">
							<div :class="'seller-module-' + ticket.sales_channel_module_id">{{ ticket.sales_channel_title }}</div>
						</td>
						<td class="desktop date-used" v-if="ticket.date_used">{{ ticket.date_used }}</td>
						<td class="desktop date-used not-used" v-else>Ещё не пришел</td>

					</tr>
					</tbody>
				</table>
				<div v-else-if="tickets !== null"
				     class="text-center text-muted small">На выбранный сеанс нет ни одного бейджа
				</div>
			</div>
		</div>


	</div>
</template>

<script>
	import CustomInput                    from '@/common/components/CustomInput/index.vue';
	import { mapGetters }                 from 'vuex';
	import moment                         from 'moment';
	import BOnceButton                    from '@/common/components/BOnceButton/index.vue';
	import { BIconArrowCounterclockwise } from 'bootstrap-vue';

	export default {
		name       : 'TicketsListIndex',
		components : { BOnceButton, CustomInput, BIconArrowCounterclockwise },
		data() {
			return {
				events        : null,
				selectedEvent : null,
				schedule      : null,
				selectedDate  : moment().format('YYYY-MM-DD'),
				selectedTime  : null,
				tickets       : null,
				watchersReady : false
			};
		},

		methods : {
			async loadEvents() {
				this.events = null;
				this.events = await this.$API.ticketsList.getEvents(this.selectedDate, this.locationId, this.hallId);

			},
			async loadSchedule() {
				this.schedule = await this.$API.ticketsList.getSchedule(this.selectedEvent.id, this.selectedDate);
				if (this.schedule.length === 1) {
					this.selectedTime = this.schedule[0].time;
				}
			},
			async loadTickets() {
				this.tickets = await this.$API.ticketsList.getTickets(this.selectedEvent.id, this.selectedDate, this.selectedTime);
			}
		},

		watch : {
			selectedEvent(newValue, oldValue) {
				if (!this.watchersReady) {
					return;
				}

				this.schedule     = null;
				this.selectedTime = null;
				this.tickets      = null;

				if (!newValue) {
					return;
				}

				if (newValue.schedule_type === 'SCHEDULE') {
					this.loadSchedule();
				}

				if (!this.hasTime) {
					this.loadTickets();
				}
			},

			async selectedDate(newValue) {
				if (!this.watchersReady) {
					return;
				}

				if (!newValue) {
					return;
				}

				await this.loadEvents();
				if (this.selectedEvent) {
					await this.loadSchedule();
				}
			},

			selectedTime(newValue) {
				if (!this.watchersReady) {
					return;
				}

				if (!newValue) {
					return;
				}

				this.loadTickets();
			}
		},

		computed : {
			...mapGetters([
				'hallId',
				'locationId'
			]),
			eventOptions() {
				return _.map(this.events, (event) => {
					return {
						value : event,
						text  : event.id + ': ' + event.title
					};
				});
			},

			eventsReady() {
				return !!this.events;
			},

			hasDate() {
				return this.selectedEvent && this.selectedEvent.schedule_type !== 'NONE';
			},

			hasTime() {
				return this.selectedEvent && this.selectedEvent.schedule_type === 'SCHEDULE';

			},

			dateOptions() {
				if (this.selectedEvent && this.selectedEvent.schedule_type === 'SCHEDULE') {
					return _.map(this.schedule, (item) => {
						return {
							value : item.date,
							text  : item.date
						};
					});
				}
				else {
					return [
						{
							value : this.$moment().format('Y-m-d'),
							text  : this.$moment().format('d MMM Y')
						}
					];
				}
			},

			timeOptions() {
				if (this.hasTime && this.selectedDate) {
					const timesForDate = _.filter(this.schedule, (item) => item.date === this.selectedDate);
					return _.map(timesForDate, (item) => {
						return {
							value : item.time,
							text  : item.time.substring(0, 5)
						};
					});
				}
				else {
					return [];
				}
			}
		},

		async mounted() {
			await this.$store.dispatch('getLoggedInData');
			if (this.selectedDate) {
				await this.loadEvents();
			}

			this.watchersReady = true;
		}
	};
</script>

<style lang="scss">
	.ticket-list-index {
		.form-group .form-group {
			margin-bottom: 0;
		}

		.form-group > div {
			display: flex;
			align-items: baseline;
			gap: 10px;
		}

		.mx-datepicker {
			width: 100%;
		}

		.product-select-wrapper {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-column-gap: 10px;

			.field-date {
				grid-column: 1;
				grid-row: 1;
			}

			.field-time {
				grid-row: 1;
				grid-column: 2;
			}

			.field-event {
				grid-column: 1 / span 2;
				grid-row: 2;
			}

			@media (min-width: 768px) {
				grid-template-columns: 150px auto 150px;
				grid-column-gap: 10px;

				.field-date {
					grid-column: 1;
					grid-row: 1;
				}

				.field-time {
					grid-row: 1;
					grid-column: 3;
				}

				.field-event {
					grid-column: 2;
					grid-row: 1;
				}
			}

			.reload-button {
				width: 60px;
			}

		}

		.ticket-line {
			&.used td {
				background-color: #d9ffd9;
			}

			.not-specified {
				color: #7a7a7a;
				font-size: 80%;
			}

			.date-used.not-used {
				color: red;
			}

			.mobile {
				.number, .email, .date-used {
					font-size: 80%;
				}

				.number, .email {
					color: #7a7a7a;
				}

				.seller-name {
					border-left: 5px solid #999999;
					padding-left: 10px;

					&.seller-module-SalesChannelSite {
						$color: #00ff00;
						border-left-color: $color;
						color: darken($color, 15);
					}

					&.seller-module-SalesChannelCash {
						$color: #00eaff;
						border-left-color: $color;
						color: darken($color, 15);
					}

					&.seller-module-SalesChannelAgent {
						$color: #ffb700;
						border-left-color: $color;
						color: darken($color, 15);
					}

					&.seller-module-SalesChannelAdmin {
						$color: #ff1e00;
						border-left-color: $color;
						color: darken($color, 15);
					}

					&.seller-module-SalesChannelContract {
						$color: #bb7fff;
						border-left-color: $color;
						color: darken($color, 15);
					}

					&.seller-module-SalesChannelTerminal {
						$color: #6aa6ff;
						border-left-color: $color;
						color: darken($color, 15);
					}
				}

			}

			.seller-name, .order-number, .date-used {
				font-size: 80%;
			}

			.seller-name {
				border-left-color: #999999;
				color: #999999;
			}

			.seller-module-SalesChannelSite {
				border-left-color: #00ff00;
				color: #00ff00;
			}

			.seller-module-SalesChannelCash {
				border-left-color: #00eaff;
				color: #00eaff;
			}

			.seller-module-SalesChannelAgent {
				border-left-color: #ffb700;
				color: #ffb700;
			}
		}

		@media (max-width: 1023px) {
			.desktop {
				display: none;
			}
		}

		@media (min-width: 1024px) {
			.mobile {
				display: none;
			}
		}
	}
</style>