import Vue from 'vue';

/**
 * Глобальная шина передачи событий между "сестринскими" компонентами
 *
 * Инициализация в vue/apps/----/src/main.js
 * import GlobalBus    from '@/common/components/GlobalBus';
 * Vue.use(GlobalBus);
 *
 * Далее в коде:
 *
 *    - вешаем слушателя:
 *                        this.$GlobalBus.$on('module-changed-to', data => {this.moduleId = data});
 *
 *    - при нужде вызываем:
 *                        this.$GlobalBus.$emit('module-changed-to', moduleId);
 *
 * @type {Vue}
 */
const globalBus = new Vue();

export default {
	install(Vue) {
		Vue.$GlobalBus = globalBus;
		Object.defineProperties(Vue.prototype, {
			$GlobalBus : {
				get() {
					return globalBus;
				},
			},
		});
	},
};
