<template>
	<div class="select-location-screen inner-layout">
		<header class="header page-header long-text">Настройки расположения и зала</header>

		<div class="container">
			<div v-if="canSelectLocation">
				<b-form-group label="Выберите расположение:">
					<b-form-select id="select-location-input" v-model="selectedLocation" :options="locationOptions" @change="clearHall"/>
					<b-button variant="link" @click="clearLocation" v-if="selectedLocation">Очистить</b-button>
				</b-form-group>

				<b-form-group label="Выберите зал:" v-if="selectedLocationHalls.length > 0">
					<b-form-select id="select-hall-input" v-model="selectedHall" :options="hallOptions"/>
					<b-button variant="link" @click="clearHall" v-if="selectedHall">Очистить</b-button>
				</b-form-group>

				<b-form-group>
					<b-button id="submit-button" variant="primary" @click="save" type="submit">Сохранить</b-button>
					<b-button variant="outline-secondary" :to="{name: 'Main'}" type="button">Назад</b-button>
				</b-form-group>
			</div>

			<div v-else>
				<p class="error-message">
					Данному валидатору запрещено самостоятельно выбирать местоположение и зал. Чтобы разрешить это, обратитесь к администратору системы.
				</p>
				<p>
					<b-button variant="outline-secondary" :to="{name: 'Main'}" type="button">Назад</b-button>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';

	export default {
		name : 'SelectLocation',
		data() {
			return {
				selectedLocation : null,
				selectedHall     : null,
				locations        : [],
				halls            : []
			};
		},
		computed : {
			...mapGetters([
				'loggedInData',
				'userSelectedLocationId',
				'userSelectedHallId'
			]),

			canSelectLocation() {
				return this.loggedInData.user_selectable_location;
			},

			locationOptions() {
				return _.map(this.locations, (location) => {
					return {
						value : location,
						text  : location.id + ': ' + location.full_title
					};
				});

			},

			hallOptions() {
				return _.map(this.selectedLocationHalls, (hall) => {
					return {
						value : hall,
						text  : hall.id + ': ' + hall.title
					};
				});
			},

			selectedLocationHalls() {
				if (!this.selectedLocation) {
					return [];
				}

				return _.filter(this.halls, (hall) => hall.location_id === this.selectedLocation.id);
			}
		},

		async mounted() {
			await this.$store.dispatch('getLoggedInData');

			if (this.canSelectLocation) {
				const locationsAndHalls = await this.$API.settings.getLocationsAndHalls();
				this.locations          = locationsAndHalls.locations;
				this.halls              = locationsAndHalls.halls;

				if (this.userSelectedLocationId) {
					this.selectedLocation = _.find(this.locations, (l) => l.id === this.userSelectedLocationId);
				}
				if (this.userSelectedHallId) {
					this.selectedHall = _.find(this.halls, (h) => h.id === this.userSelectedHallId);
				}

			}
		},
		methods : {
			async save() {
				await this.$store.dispatch('saveLocationAndHall', {
					locationId : this.selectedLocation?.id,
					hallId     : this.selectedHall?.id
				});
				this.$router.push({ name : 'Main' });
			},
			clearLocation() {
				this.selectedLocation = null;
			},
			clearHall() {
				this.selectedHall = null;
			}
		}
	};
</script>

<style scoped>
	.error-message {
		color: red;
	}
</style>