<template>
	<div class="main-layout">
		<main-menu/>
		<router-view/>
	</div>
</template>

<script>
	import MainMenu from '@/components/Main/MainMenu.vue';

	export default {
		name       : 'MainLayout',
		components : { MainMenu }
	};
</script>

<style scoped>

</style>