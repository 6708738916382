import * as common                      from '@/api/common';
import { getAuth, removeAuth, setAuth } from '@/common/helpers/localStorage';

const user = {
	state : {
		user     : false, // Имя пользователя
		token    : false, // Авторизационный ключ
		loggedIn : false
	},

	mutations : {
		SET_TOKEN     : (state, token) => {
			state.token = token;
		},
		SET_USER      : (state, user) => {
			state.user = user;
		},
		SET_LOGGED_IN : (state, res) => {
			state.loggedIn = res;
		}
	},

	actions : {
		/**
		 * Получения у установка авторизационных данных пользователя
		 *
		 * @param commit
		 * @param dispatch
		 * @param rootState
		 */
		async auth({ commit, dispatch, rootState }) {
			const authInfo = getAuth();

			if (authInfo.user && authInfo.token) {
				commit('SET_USER', authInfo.user);
				commit('SET_TOKEN', authInfo.token);
				commit('SET_LOGGED_IN', true);

				await dispatch('getLoggedInData');
				if (rootState.app.loggedInData.user_selectable_location) {
					await dispatch('getLocationsAndHalls');
				}
			}

			return Promise.resolve();
		},
		/**
		 * Авторизация пользователя и установка данных
		 *
		 * @param commit
		 * @param dispatch
		 * @param state
		 * @param userInfo
		 * @returns {Promise<any>}
		 */
		login({ commit, dispatch, rootState }, userInfo) {
			const username = userInfo.username.trim();
			let self       = this._vm;

			return new Promise((resolve, reject) => {
				common.login(username, userInfo.password)
				      .then(async data => {
					      console.log(data);
								commit('SET_USER', data.user);
								commit('SET_TOKEN', data.token);
					      setAuth(null, data.user, data.token);
					      self.$GlobalBus.$emit('loggedIn');

					      try {
						      await dispatch('getLoggedInData');

						      commit('SET_LOGGED_IN', true);
						      resolve(data);
					      }
					      catch (e) {
						      commit('SET_USER', false);
						      commit('SET_TOKEN', false);
						      removeAuth();

						      reject(e);
					      }
				      })
				      .catch(error => {
					      reject(error);
				      });
			});
		},
		/**
		 * Запрос на выход пользователя
		 *
		 * @param commit
		 * @param state
		 * @returns {Promise<any>}
		 */
		logout({ commit, state }) {
			let self = this._vm;
			// return new Promise((resolve, reject) => {
			// 	common.logout()
			// 	      .then(() => {
					      commit('SET_USER', false);
					      commit('SET_TOKEN', false);
					      commit('SET_LOGGED_IN', false);
					      removeAuth();
					      self.$GlobalBus.$emit('loggedOut');
					    //   resolve();
				      // })
				      // .catch(error => {
					    //   reject(error);
				      // });
			// });
		}
	}
};

export default user;
