const getters = {
	language                     : state => state.app.language,
	token                        : state => state.user.token,
	user                         : state => state.user.user,
	errorLogs                    : state => state.errorLog.logs,
	loggedInData                 : state => state.app.loggedInData,
	isLoggedIn                   : state => !!state.user.token && !!state.user.user && state.user.loggedIn,
	isUserSelectedLocationOrHall : state => state.app.loggedInData.user_selectable_location && Boolean(state.app.locationId || state.app.hallId),
	userSelectedLocationId       : state => state.app.locationId,
	userSelectedHallId           : state => state.app.hallId,
	locationId                   : (state, getters) => getters.isUserSelectedLocationOrHall ? getters.userSelectedLocationId : state.app.loggedInData.location_id,
	hallId                       : (state, getters) => getters.isUserSelectedLocationOrHall ? getters.userSelectedHallId : state.app.loggedInData.hall_id,
	userSelectedLocation         : state => state.app.locationId ? _.find(state.app.locations, (l) => l.id === state.app.locationId) : null,
	userSelectedHall             : state => state.app.hallId ? _.find(state.app.halls, (h) => h.id === state.app.hallId) : null,
	validatorLocationTitle       : (state, getters) => getters.isUserSelectedLocationOrHall ? getters.userSelectedLocation?.title : state.app.loggedInData.location_title,
	validatorHallTitle           : (state, getters) => getters.isUserSelectedLocationOrHall ? getters.userSelectedHall?.title : state.app.loggedInData.hall_title,
	checkPermission              : state => (perm, isNot, positiveSuperadmin) => {
		if (!perm || (perm instanceof String && typeof perm !== 'string')) {
			return false;
		}

		if (state.user.superadmin === true) {
			return positiveSuperadmin ? true : !isNot;
		}

		if (!Array.isArray(perm)) {
			perm = [perm];
		}

		if (perm[0].toUpperCase() === 'OR') {
			perm.shift();

			return perm.some(value => {
				let res = state.user.permissions.includes(value);
				return isNot ? !res : res;
			});
		}

		return perm.every(value => {
			let res = state.user.permissions.includes(value);
			return isNot ? !res : res;
		});
	},
	// FIXME: в этом приложении нет констант. Чтобы работал общий механизм, нужно чтобы они тут были. Пока что хардкодим.
//	appId           : state => state.app.constants['Global::APP_VALIDATOR'],
	appId : state => 'validator'
};

export default getters;
