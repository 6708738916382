import * as common                                                             from '@/api/common';
import * as settings                                                           from '@/api/settings';
import { getLocalStorageParam, removeLocalStorageParam, setLocalStorageParam } from '@/common/helpers/localStorage';

const app = {
	state     : {
		language     : getLocalStorageParam('language', 'ru'),
		loggedInData : {},
		locationId   : getLocalStorageParam('location_id') ? Number(getLocalStorageParam('location_id')) : null,
		hallId       : getLocalStorageParam('hall_id') ? Number(getLocalStorageParam('hall_id')) : null,
		locations    : [],
		halls        : []
	},
	mutations : {
		SET_LANGUAGE                   : (state, language) => {
			state.language = language;
			setLocalStorageParam('language', language);
		},
		SET_LOGGED_IN_DATA             : (state, data) => {
			state.loggedInData = data;
		},
		SET_SELECTED_LOCATION_AND_HALL : (state, { locationId, hallId }) => {
			state.locationId = locationId;
			state.hallId     = hallId;
		},
		SET_LOCATIONS_AND_HALLS        : (state, data) => {
			state.locations = data.locations;
			state.halls     = data.halls;
		}
	},
	actions   : {
		/**
		 * Установка языка
		 *
		 * @param commit
		 * @param language
		 */
		setLanguage({ commit }, language) {
			commit('SET_LANGUAGE', language);
		},
		/**
		 * Получение данных об авторизации
		 *
		 * @param commit
		 * @returns {Promise<*>}
		 */
		async getLoggedInData({ commit }) {
			try {
				let data = await common.getLoggedInData();
				commit('SET_LOGGED_IN_DATA', data);
				return Promise.resolve(data);
			}
			catch (e) {
				return Promise.reject(e);
			}
		},

		/**
		 * Получение списка локаций и залов (нужно для вывода названия выбранных локации и зала)
		 * @param commit
		 *
		 * @returns {Promise<any>}
		 */
		async getLocationsAndHalls({ commit }) {
			try {
				const data = await settings.getLocationsAndHalls();
				commit('SET_LOCATIONS_AND_HALLS', data);
				return Promise.resolve(data);
			}
			catch (e) {
				return Promise.reject(e);
			}
		},

		/**
		 * Запись выбранных локации и зала
		 *
		 * @param commit
		 * @param locationId
		 * @param hallId
		 */
		saveLocationAndHall({ commit }, { locationId, hallId }) {
			console.log(locationId, hallId);
			if (locationId) {
				setLocalStorageParam('location_id', locationId);
			}
			else {
				removeLocalStorageParam('location_id');
			}

			if (hallId) {
				setLocalStorageParam('hall_id', hallId);
			}
			else {
				removeLocalStorageParam('hall_id');
			}

			commit('SET_SELECTED_LOCATION_AND_HALL', { locationId, hallId });
		}
	}
};

export default app;
