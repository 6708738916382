import notfound from '@/assets/sounds/notfound.mp3';
import ok       from '@/assets/sounds/ok.mp3';
import used     from '@/assets/sounds/used.mp3';

const sounds = {
	notfound,
	ok,
	used,
};

/**
 * Проигрывание звука
 *
 * @param sndName
 */
export function playSound(sndName) {
	try {
		let audio = new Audio(sounds[sndName]);
		audio.play();

	}
	catch (e) {
		console.error('Oops', e);
	}

}

/**
 * Проигрывание звука "ОК"
 */
export function playOk() {
	playSound('ok');
}

/**
 * Проигрывание звука ошибки
 */
export function playError() {
	playSound('notfound');
}

/**
 * Проигрывание звука "билет использован"
 */
export function playUsed() {
	playSound('used');
}
