<template>
	<b-form-group v-if="loaded"
	              :invalid-feedback="textFormatError"
	              :label-size="size"
	              :state="error ? false : (valid ? true : null)"
	              :class="{'custom-input': true, 'required': required}">
		<template v-if="formGroupLabel || subtitle || $scopedSlots.label"
		          v-slot:label>
			<template v-if="formGroupLabel">
				{{ formGroupLabel }}
				<font-awesome-icon class="font-awesome-icon"
				                   v-if="popover"
				                   size="sm"
				                   v-b-popover.hover.bottom="{ variant: 'info', content: popover }"
				                   variant="outline-info"
				                   :icon="['fas', 'question-circle']"/>
			</template>

			<slot name="label"></slot>
			<template v-if="subtitle">
				<p class="custom-input-subtitle">{{ subtitle }}</p>
			</template>
		</template>

		<!-- Превью загруженного изображения -->
		<div class="image-preview-box" v-if="preview">
			<img class="image-preview" :src="emittedValue" v-if="preview && emittedValue" alt="">
			<img class="image-preview" :src="oldUrl" v-else-if="preview && oldUrl" alt="">
		</div>

		<b-input-group :size="size" :class="type">
			<slot>
				<v-select v-if="type === 'select'"
				          v-model="emittedValue"
				          :placeholder="placeholder"
				          @input="changeValue($event)"
				          :disabled="disabled"
				          :filterable="false"
				          :clearable="false"
				          :multiple="config.multiple"
				          :label="config.optionLabelAttr || 'label'"
				          :reduce="option => option[config.optionValueAttr || 'value']"
				          :options="getSelectOptions(config.options)"
				          class="form-control">
					<template #no-options="{ search, searching, loading }">
						Список пуст
					</template>

					<template #search="{attributes, events}">
						<input :name="name" v-bind="attributes" v-on="events" class="vs__search"/>
					</template>
				</v-select>

				<b-form-select v-else-if="type === 'boolean'"
				               v-model="emittedValue"
				               @change="changeValue($event)"
				               :placeholder="placeholder"
				               :name="name"
				               :disabled="disabled">
					<b-select-option :value="checkedValue">Да</b-select-option>
					<b-select-option :value="uncheckedValue">Нет</b-select-option>
				</b-form-select>

				<b-form-checkbox v-else-if="type === 'checkbox'"
				                 v-model="emittedValue"
				                 :state="error ? false : valid ? true : null"
				                 @change="changeValue($event)"
				                 :placeholder="placeholder"
				                 :value="checkedValue"
				                 :unchecked-value="uncheckedValue"
				                 :name="name"
				                 :button="config.button"
				                 :button-variant="variant"
				                 :disabled="disabled"
				                 :switch="checkboxSwitch"
				                 :size="size">
					<slot name="checkbox">
						{{ label }}
						<font-awesome-icon class="font-awesome-icon"
						                   v-if="popover"
						                   size="sm"
						                   v-b-popover.hover.bottom="{ variant: 'info', content: popover }"
						                   variant="outline-info"
						                   :icon="['fas', 'question-circle']"/>
					</slot>
				</b-form-checkbox>

				<template v-else-if="type === 'select-remote'">
					<select-remote ref="selectRemote"
					               v-model="emittedValue"
					               :list-method="config.listMethod"
					               :label-attr="config.labelAttr"
					               :query="config.query"
					               :limit="config.limit"
					               :join="config.join"
					               :placeholder="placeholder"
					               :selectable="config.selectable"
					               :first-loading="config.firstLoading"
					               :use-grouping="config.useGrouping"
					               :group-label-attr="config.groupLabelAttr"
					               :immediate-exclude="config.immediateExclude"
					               :append-items="config.appendItems"
					               :prepend-items="config.prependItems"
					               :with-no-active="withNoActive || config.withNoActive"
					               :clearable="false"
					               :disabled="disabled"
					               :append-to-body="appendToBody"
					               :name="name"
					               :multiple="config.multiple"
					               :allow-create="allowCreate"
					               @change="changeValue($event)">
						<template #option="item">
							<slot name="option" v-bind="item"></slot>
						</template>

						<template #selected-option="item">
							<slot name="selected-option" v-bind="item"></slot>
						</template>
					</select-remote>
				</template>

				<template v-else-if="type === 'tree-selector'">
					<cascader v-model="emittedValue"
					          :list-method="listMethod"
					          :disabled="disabled"
					          :multiple="multiple"
					          :placeholder="placeholder"
					          :query="config.query"
					          :clearable="false"
					          :size="size"
					          :with-no-active="withNoActive"
					          @change="changeValue($event)">
						<template v-slot="item">
							<slot name="tree-selector" v-bind="item" />
						</template>
					</cascader>
				</template>

				<template v-else-if="type === 'date'">
					<date-picker v-model="emittedValue"
					             type="date"
					             :append-to-body="appendToBody"
					             :editable="dateTimeEditable"
					             format="DD.MM.YYYY"
					             value-type="YYYY-MM-DD"
					             input-class="form-control"
					             :clearable="false"
					             :range="config.range"
					             :disabled-date="config.disabledDate"
					             :disabled="disabled"
					             :input-attr="{ name: name }"
					             :placeholder="placeholder"
					             :confirm="confirmEnabled"
					             @confirm="confirmCallback"
					             @change="changeValue($event)"/>
				</template>

				<template v-else-if="type === 'time'">
					<date-picker v-model="emittedValue"
					             type="time"
					             :append-to-body="appendToBody"
					             format="HH:mm"
					             input-class="form-control"
					             value-type="HH:mm:00"
					             :clearable="false"
					             :range="config.range"
					             :disabled="disabled"
					             :editable="true"
					             :input-attr="{ name: name }"
					             :placeholder="placeholder"
					             :confirm="confirmEnabled"
					             @confirm="confirmCallback"
					             @change="changeValue($event)"/>
				</template>

				<template v-else-if="type === 'datetime'">
					<date-picker v-model="emittedValue"
					             type="datetime"
					             :append-to-body="appendToBody"
					             format="DD.MM.YYYY HH:mm"
					             :editable="dateTimeEditable"
					             :show-time-header="true"
					             input-class="form-control"
					             value-type="YYYY-MM-DD HH:mm:ss"
					             :clearable="false"
					             :range="config.range"
					             :disabled="disabled"
					             :placeholder="placeholder"
					             :input-attr="{ name: name }"
					             :confirm="confirmEnabled"
					             @confirm="confirmCallback"
					             @change="changeValue($event)"/>
				</template>

				<template v-else-if="type === 'radio'">
					<b-radio-group v-model="emittedValue"
					               :placeholder="placeholder"
					               :state="error ? false : null"
					               :buttons="config.buttons || false"
					               :stacked="config.stacked || false"
					               :button-variant="variant"
					               @change="changeValue($event)"
					               :size="size"
					               :disabled="disabled"
					               :name="name">

						<b-radio v-if="config.prependItems"
						         :state="error ? false : valid ? true : null"
						         v-for="item in config.prependItems"
						         :key="item.id"
						         :value="item.id">{{ item.title }}
						</b-radio>

						<b-radio v-for="(item, id, index) in getSelectOptions(config.options)"
						         :key="typeof item == 'string' ? id : item[config.optionValueAttr || 'value']"
						         :disabled="config.disabled ? config.disabled[index][id] : false"
						         :value="typeof item == 'string' ? id : item[config.optionValueAttr || 'value']">
							<span v-if="config.html"
							      v-html="typeof item == 'string' ? item : item[config.optionLabelAttr || 'label']"></span>
							<template v-else>{{ typeof item == 'string' ? item : item[config.optionLabelAttr || 'label'] }}</template>
						</b-radio>

						<b-radio v-if="config.appendItems"
						         :state="error ? false : valid ? true : null"
						         v-for="item in config.appendItems"
						         :key="item.id"
						         :value="item.id">{{ item.title }}
						</b-radio>

					</b-radio-group>
				</template>

				<template v-else-if="type === 'seconds'">
					<b-row :class="type" v-if="emittedValue">
						<b-col cols="3"
						       class="pr-0"
						       v-if="secondsWithSign">
							<b-select v-model="emittedValue.type"
							          :size="size"
							          :state="error ? false : valid ? true : null"
							          @change="changeValue($event)">
								<b-select-option value="+">После</b-select-option>
								<b-select-option value="-">До</b-select-option>
							</b-select>
						</b-col>
						<b-col class="pr-0" :class="{'pl-0': secondsWithSign }">
							<div class="seconds-label" :style="{left: secondsWithSign ? '4px' : '20px'}">Дни</div>
							<b-input v-model.number="emittedValue.days"
							         type="number"
							         :state="error ? false : valid ? true : null"
							         :size="size"
							         @change="changeValue($event)"/>
						</b-col>
						<b-col class="pr-0 pl-0">
							<div class="seconds-label">Часы</div>
							<b-input v-model.number="emittedValue.hours"
							         type="number"
							         :state="error ? false : valid ? true : null"
							         :size="size"
							         @change="changeValue($event)"/>
						</b-col>
						<b-col class="pl-0">
							<div class="seconds-label">Минуты</div>
							<b-input v-model.number="emittedValue.minutes"
							         type="number"
							         :state="error ? false : valid ? true : null"
							         :size="size"
							         @change="changeValue($event)"/>
						</b-col>
					</b-row>
				</template>

				<!-- Номер телефона -->
				<phone-input v-else-if="type === 'phone'"
				             v-model="emittedValue"
				             :disabled="disabled"
				             default-country="ru"
				             :preferred-countries="['ru', 'by', 'ua']"
				             :auto-default-country="true"
				             :valid-characters-only="true"
				             mode="international"
				             :input-options="{ placeholder: placeholder }"/>

				<!-- Textarea -->
				<b-textarea v-else-if="type === 'textarea'"
				            v-model="emittedValue"
				            :disabled="disabled"
				            :state="error ? false : null"
				            :placeholder="placeholder"
				            :debounce="debounce"
				            :name="name"
				            :rows="rows"
				            @change="changeValue($event)"/>

				<!-- Файл (контент файла кладется в поле) -->
				<template v-else-if="type === 'file'">
					<b-button @click="$refs.fileInput.click()">{{ buttonLabel }}</b-button>
					<input type="file"
					       ref="fileInput"
					       :disabled="disabled"
					       class="file-uploader-input"
					       @change="onFileInputChange($event)"
					       :accept="accept"/>
				</template>

				<!-- Цвет -->
				<color-picker v-else-if="type === 'color'"
				              v-model="emittedValue"
				              :mode="colorMode"
				              :alpha="colorAlpha"
				              @change="changeValue($event)"></color-picker>

				<!-- Тип поля - обычный текст -->
				<b-input v-else
				         v-model="emittedValue"
				         :type="type"
				         :state="error ? false : null"
				         :placeholder="placeholder"
				         :debounce="debounce"
				         :disabled="disabled"
				         :plaintext="plaintext"
				         :readonly="readonly"
				         :max-word="1"
				         :min="min"
				         :max="max"
				         :step="step"
				         :name="name"
				         :size="size"
				         @focus="$emit('focus')"
				         @focusin="$emit('focusin')"
				         @focusout="$emit('focusout')"
				         :formatter="inputFormatter"
				         :trim="!type || type=== 'text'"
				         @change="changeValue($event)"/>
			</slot>

			<b-input-group-append v-if="!readonly && clearable && type !== 'checkbox'">
				<b-button :size="size"
				          variant="outline-warning"
				          @click="clear"
				          :disabled="disabled">
					<font-awesome-icon :icon="['fas', 'times']"/>
				</b-button>
			</b-input-group-append>
			<slot name="append"></slot>
		</b-input-group>

		<slot name="footer"></slot>
		<!-- Информация о выбранном файле -->
		<p v-if="selectedFileName">{{ selectedFileName }}</p>

		<slot name="after"></slot>
	</b-form-group>
</template>

<script>
	import SelectRemote from '@/common/components/SelectRemote/index';
	import ColorPicker  from '@/common/components/ColorPicker';
	import Cascader     from '@/common/components/Cascader';

	export default {
		name          : 'CustomInput',
		componentName : 'CustomInput',
		components    : { SelectRemote, ColorPicker, Cascader },
		props         : {
			value       : [
				String,
				Number,
				Date,
				Array,
				Boolean
			],
			label       : String,
			config      : {
				type : Object,
				default() {
					return {};
				}
			},
			type        : String,
			error       : [String, Array],
			valid       : String,
			size        : String,
			placeholder : String,
			clearable   : {
				type    : Boolean,
				default : true
			},
			disabled    : Boolean,
			min         : [Number, String],
			max         : [Number, String],
			step        : [Number, String],
			/**
			 * @param {Boolean}
			 */
			required : {
				type    : Boolean,
				default : false
			},
			join     : [Array, String],
			/**
			 * Разрешить создание элемента списка в select-remote
			 */
			allowCreate : {
				type    : Boolean,
				default : false
			},
			/**
			 * Оставить введенную строку в select-remote
			 */
			keepSearchString : {
				type    : Boolean,
				default : false
			},
			name             : String,
			rows             : [Number, String],
			accept           : String,
			preview          : Boolean,
			buttonLabel      : {
				type    : String,
				default : 'Выбрать файл...'
			},
			oldUrl           : String,
			popover          : String,
			subtitle         : String,
			/**
			 * Оформление инпута как простой текст
			 */
			plaintext : {
				type    : Boolean,
				default : false
			},
			/**
			 * Только для чтения
			 */
			readonly : {
				type    : Boolean,
				default : false
			},
			/**
			 * Вставлять popup в body
			 */
			appendToBody : {
				type    : Boolean,
				default : false
			},
			/**
			 * Задержка
			 */
			debounce : [String, Number],
			/**
			 * Режим switch для checkbox
			 */
			checkboxSwitch : {
				type    : Boolean,
				default : false
			},
			/**
			 * Вариант отображения кнопок
			 */
			variant : String,
			/**
			 * Можно ли редактировать дату с клавиатуры
			 */
			dateTimeEditable : {
				type    : Boolean,
				default : false
			},
			/**
			 * Использовать подтверждение при вводе
			 */
			confirmEnabled : {
				type    : Boolean,
				default : false
			},
			/**
			 * Функция, вызываемая после подтверждения
			 */
			confirmCallback : {
				type    : Function,
				default : () => {
				}
			},
			/**
			 * Режим color-picker. HEX или RGB или оба
			 */
			colorMode : {
				type    : [Array, String],
				default : 'hex'
			},
			/**
			 * Использовать прозрачность в color-picker
			 */
			colorAlpha : {
				type    : Boolean,
				default : false
			},
			/**
			 * Для checkbox и boolean. Значение при включении
			 */
			checkedValue : {
				type    : [Number, String, Boolean],
				default : 1
			},
			/**
			 * Для checkbox и boolean. Значение при выключении
			 */
			uncheckedValue : {
				type    : [Number, String, Boolean],
				default : 0
			},
			/**
			 * Метод для получения данных
			 */
			listMethod : Function,
			/**
			 * Множественный выбор
			 */
			multiple : {
				type    : Boolean,
				default : false
			},
			/**
			 * Запрос списка с сервера вместе с выключенными элементами (active = 0)
			 */
			withNoActive: Boolean
		},

		data() {
			return {
				emittedValue     : null,
				selectedFileName : null,
				selectedFileType : null,
				loaded           : false
			};
		},
		computed : {
			/**
			 *
			 * @returns {string|null}
			 */
			convertedSize() {
				switch (this.size) {
					case 'sm':
						return 'small';
					case 'lg':
						return 'large';
					default:
						return null;
				}
			},
			/**
			 *
			 * @returns {*|boolean}
			 */
			secondsWithSign() {
				return this.config && this.config.hasOwnProperty('withSign') ? this.config.withSign : true;
			},
			/**
			 *
			 * @returns {null}
			 */
			convertedSeconds() {
				let seconds = null;
				if (this.emittedValue.days) {
					seconds = +seconds + this.emittedValue.days * 86400;
				}

				if (this.emittedValue.hours) {
					seconds = +seconds + this.emittedValue.hours * 3600;
				}

				if (this.emittedValue.minutes) {
					seconds = +seconds + this.emittedValue.minutes * 60;
				}

				if (seconds !== null && this.secondsWithSign && this.emittedValue.type === '-') {
					seconds = Math.abs(seconds) * -1;
				}

				return seconds;
			},
			/**
			 *
			 * @returns {string|String}
			 */
			formGroupLabel() {
				return this.type === 'checkbox' && !this.$scopedSlots.checkbox ? '' : this.label;
			},
			/**
			 * @returns {string}
			 */
			textFormatError() {
				if (this.error && Array.isArray(this.error)) {
					return this.error.join(', ');
				}
				else {
					return this.error;
				}
			}
		},
		mounted() {
			this.setEmittedValue();

			this.$watch('value', () => {
				this.setEmittedValue();
			});

			this.$watch('emittedValue', (val) => {
				this.$emit('input', this.type !== 'seconds' ? val : this.convertedSeconds);
			}, { deep : true });

			this.loaded = true;
		},
		methods : {
			/**
			 *
			 */
			setEmittedValue() {
				if (this.type === 'seconds') {
					if (!this.emittedValue) {
						let parsedSeconds = {
							days    : null,
							hours   : null,
							minutes : null
						};

						if (this.secondsWithSign) {
							parsedSeconds['type'] = '+';
						}

						this.$set(this, 'emittedValue', parsedSeconds);
					}

					if (this.value) {
						let seconds               = Math.abs(this.value);
						this.emittedValue.days    = Math.floor(seconds / 86400);
						seconds %= 86400;
						this.emittedValue.hours   = Math.floor(seconds / 3600);
						seconds %= 3600;
						this.emittedValue.minutes = Math.floor(seconds / 60);
						if (this.secondsWithSign && +this.value < 0) {
							this.emittedValue.type = '-';
						}
					}
				}
				else if (this.type === 'number' && this.value !== null && typeof this.value !== 'undefined') {
					this.emittedValue = +this.value;
				}

				else {
					this.$set(this, 'emittedValue', this.value);
				}

			},
			/**
			 *
			 */
			clear() {
				if (this.type !== 'seconds') {
					this.emittedValue = null;
				}
				else {

					this.emittedValue.days    = null;
					this.emittedValue.hours   = null;
					this.emittedValue.minutes = null;

					if (this.secondsWithSign) {
						this.emittedValue.type = '+';
					}
				}

				this.selectedFileName = null;
				this.selectedFileType = null;

				this.$emit('change', null);
			},
			/**
			 *
			 * @param options
			 * @returns {*}
			 */
			getSelectOptions(options) {
				if (!options) {
					return options;
				}

				let opt = typeof options === 'function' ? options() : options;
				if (!opt) {
					return opt;
				}

				if (this.type === 'select') {
					let newOpt = opt;
					if (typeof opt === 'object' && !Array.isArray(opt)) {
						newOpt = [];
						this._.each(opt, (item, key) => {
							let obj                                     = {};
							obj[this.config.optionValueAttr || 'value'] = key;
							obj[this.config.optionLabelAttr || 'label'] = item;

							newOpt.push(obj);
						});

						opt = newOpt;
					}

					if (this.config.prependItems && this.config.prependItems.length) {
						opt = this.config.prependItems.concat(opt);
					}

					if (this.config.appendItems && this.config.appendItems.length) {
						opt = opt.concat(this.config.appendItems);
					}
				}

				return opt;
			},
			/**
			 *
			 * @param value
			 */
			changeValue(value) {
				if (this.type === 'seconds') {
					this.$emit('change', this.convertedSeconds);

					return;
				}

				if (this.type === 'number') {
					this.$emit('change', +value);
					return;
				}

				this.$emit('change', value);
			},
			/**
			 *
			 * @param withoutValue
			 */
			reset(withoutValue) {
				switch (this.type) {
					case 'select-remote':
						this.$refs.selectRemote.reset(withoutValue);

						break;
				}
			},
			/**
			 *
			 */
			getSelectItems(selectIfOne) {
				switch (this.type) {
					case 'select-remote':
						this.$refs.selectRemote.onSearch(null, null, selectIfOne);

						break;
				}
			},

			onFileInputChange(e) {
				let files = e.target.files || e.dataTransfer.files;
				if (!files.length) {
					return;
				}

				this.selectedFileName = files[0].name;
				this.selectedFileType = files[0].type;
				this.$emit('selectedFileName', this.selectedFileName);
				this.$emit('selectedFileName', this.selectedFileName);

				const reader  = new FileReader();
				reader.onload = (e) => {
					this.emittedValue = e.target.result;
					this.changeValue(e.target.result);
				};
				reader.readAsDataURL(files[0]);
			},
			/**
			 * Формат значения input
			 * @param value
			 * @returns {string}
			 */
			inputFormatter(value) {
				if (value === '' || value === null) {
					return value;
				}

				if (!this.type || this.type === 'text') {
					if (this.max) {
						return value.substring(0, +this.max);
					}
				}

				if (this.type === 'number') {
					let val = value;
					if (this.min && +val < +this.min) {
						val = this.min;
					}

					if (this.max && +val > +this.max) {
						val = this.max;
					}

					return val;
				}

				return value;
			}
		}
	};
</script>
<style lang="scss">
	.custom-input {
		.mx-datepicker {
			.mx-input-wrapper {
				.form-control[readonly] {
					background-color: #fff;
				}

				.form-control:disabled {
					background-color: #e9ecef;
				}
			}
		}

		.input-group {
			&.color {
				.colorpicker {
					.color-block {
						width: 30px;
						height: 30px;
					}
				}

			}

			&.checkbox {
				.custom-switch {
					padding-left: 2.7rem;

					.custom-control-label {
						font-weight: 400;
						line-height: 1.5;
						font-size: 16px;

						&:before {
							top: 0.125rem;
							left: -2.7rem;
							width: 2.5rem;
							height: 1.25rem;
							border-radius: 1rem;
							background-color: $danger;
							border: 1px solid $danger;
						}

						&:after {
							top: calc(0.125rem + 2px);
							left: calc(-2.7rem + 2px);
							width: calc(1.25rem - 4px);
							height: calc(1.25rem - 4px);
							border-radius: 1rem;
							background-size: 50% 50%;
							background-color: #fff;
						}
					}

					.custom-control-input {
						&:checked ~ .custom-control-label {
							&:before {
								background-color: $success;
								border: 1px solid $success;
							}

							&:after {
								transform: translateX(1.28rem);
							}
						}

						&:focus ~ .custom-control-label {
							&:before {
								box-shadow: none;
							}
						}

						&:not(:disabled) ~ .custom-control-label {
							color: #212529;
						}

						&:disabled ~ .custom-control-label {
							cursor: not-allowed;

							&:before {
								opacity: 0.7;
							}

							&:after {
								opacity: 0.8;
							}

						}
					}
				}
			}
		}

		.input-group-sm {
			&.color {
				.colorpicker {
					.color-block {
						width: 23px;
						height: 23px;
					}
				}

			}
		}

		.input-group-lg {
			&.color {
				.colorpicker {
					.color-block {
						width: 40px;
						height: 40px;
					}
				}

			}
		}

		.cascader {
			position: relative;
			flex: 1 1 0%;
			min-width: 0;
			margin-bottom: 0;
		}
	}

	label.custom-control-label {
		font-size: 14px;
	}

</style>
<style lang="scss" scoped>
	.custom-input {
		font-size: 14px;

		.font-awesome-icon {
			margin-left: 3px;
			opacity: .5;
		}

		.custom-input-subtitle {
			color: #6c6c6c;
			margin-bottom: 5px;
			font-weight: normal;
		}

		.input-group:not(.seconds):not(.radio):not(.checkbox):not(.color) {
			@include nativeBootstrapInputBehavior
		}

		&.is-valid {
			.input-group:not(.seconds):not(.radio):not(.checkbox) {
				border: 1px solid $success;

				&:focus-within {
					.mx-datepicker {
						.mx-input-wrapper {
							.form-control {
								@include highlightingInputGreen
							}
						}
					}

					.custom-select,
					.form-control,
					.v-select {
						@include highlightingInputGreen
					}

					@include highlightingInputGreen
				}
			}

			.phone {
				.vue-tel-input:focus-within,
				.vue-tel-input:focus {
					border-color: $success !important;
					box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 0 3px rgba(0, 175, 0, .2) !important;
				}
			}
		}

		&.is-invalid {
			.input-group:not(.seconds):not(.radio):not(.checkbox) {
				border: 1px solid $danger;

				&:focus-within {
					.mx-datepicker {
						.mx-input-wrapper {
							.form-control {
								@include highlightingInputRed
							}
						}
					}

					.custom-select,
					.mx-datepicker,
					.v-select {
						@include highlightingInputRed
					}

					@include highlightingInputRed
				}
			}

			.phone {
				.vue-tel-input:focus-within,
				.vue-tel-input:focus {
					border-color: $danger !important;
					box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 0 3px rgba(175, 0, 0, .2) !important;

				}
			}
		}

		legend {
			align-items: center;
			font-size: 12px;

			p {
				margin-bottom: 0px;
			}
		}

		.vdp-datepicker {
			position: relative;
			flex: 1 1 0%;
			min-width: 0;
			margin-bottom: 0;
		}

		.vdp-datepicker:not(:only-child) {
			.form-control {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}

		.input-group-sm {
			.vdp-datepicker {
				.form-control {
					padding: 0.25rem 0.5rem;
					font-size: 0.875rem;
					line-height: 1.5;
					border-radius: 0.2rem 0.2rem 0.2rem 0.2rem;
					height: calc(1.5em + 0.5rem + 2px);
				}
			}

			.vdp-datepicker:not(:only-child) {
				.form-control {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}
			}

			.vue-tel-input {
				height: $input-height-sm !important;
				padding: $input-padding-y-sm $input-padding-x-sm !important;
				@include font-size($input-font-size-sm);
				line-height: $input-line-height-sm;
				@include border-radius($input-border-radius-sm);
			}
		}

		.input-group-lg {
			.vdp-datepicker {
				.form-control {
					padding: 0.5rem 1rem;
					font-size: 1.25rem;
					line-height: 1.5;
					border-radius: 0.3rem 0.3rem 0.3rem 0.3rem;
					height: calc(1.5em + 1rem + 2px);
				}
			}

			.vdp-datepicker:not(:only-child) {
				.form-control {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}
			}
		}

		.custom-control-label {
			display: inline-block;
			padding-top: 2px;
			font-weight: normal;
		}

		.custom-radio {
			display: block;
		}

		.input-group {
			&.seconds {
				padding-top: 14px;
				flex-wrap: nowrap;
			}

			.seconds {
				flex: 1 1 auto;

				.form-control {
					width: 100%;
				}

				.seconds-label {
					position: absolute;
					top: -16px;
					font-size: 12px;
					left: 4px;
				}

				.custom-select {
					background-position-x: right 0.30rem;
					background-position-y: center;
					padding-right: 1rem;
				}
			}

			&.color {
				.colorpicker {
					padding: 3px;
					border: 1px solid #ced4da;
				}

			}
		}

		.file-uploader-input {
			visibility: hidden;
			overflow: hidden;
			height: 0;
			width: 0;
		}

		.image-preview {
			max-width: 100%;
			max-height: 400px;
			border: 1px solid #333;
		}

		.image-preview-box {
			margin-bottom: 15px;
		}

	}
</style>