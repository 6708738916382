import VueRouter from 'vue-router';

import Login from '@/components/Login/index.vue';
import Main from '@/components/Main/index.vue';
import SelectLocation from '@/components/Settings/SelectLocation.vue';
import TicketListIndex from '@/components/TicketList/TicketListIndex.vue';
import MainLayout from '@/components/layouts/MainLayout.vue';

const routes = [
  {
    path     : '/',
    name     : 'Login',
    component: Login
  },
  {

    path     : '/logged-in',
    name     : 'MainLayout',
    component: MainLayout,
    children : [
      {
        path     : '/main',
        name     : 'Main',
        component: Main
      },
      {
        path     : '/select-location',
        name     : 'SelectLocation',
        component: SelectLocation
      },
      {
        path     : '/ticket-list',
        name     : 'TicketList',
        component: TicketListIndex
      }
    ]
  },

];

const Router = new VueRouter({
  mode                : 'history',
  routes,
  linkExactActiveClass: 'active'
});

Router.beforeEach((to, from, next) => {
  const store = Router.app.$store;
  const isLoggedIn = store.getters.isLoggedIn;
  if (!isLoggedIn && to.name !== 'Login') {
    next({name: 'Login'});
  }
  else if (isLoggedIn && to.name === 'Login') {
    next({name: 'Main'});
  }
  else {
    next();
  }
});

export default Router;
