<template>
	<div class="colorpanel hsv">
		<div class="colors" @mousedown="choose" ref="vcolors">
			<div class="color-pointer" :style="pointerPosition"></div>
			<div class="color-base" :style="{background: hueString}"></div>
			<div class="brightness-light"></div>
			<div class="brightness-dark"></div>
		</div>
		<div class="color-control">
			<div class="color-present">
				<div :style="{backgroundColor: rgbString}"></div>
			</div>
			<div class="color-slider">
				<div class="hue">
					<slider :vertical="false" :value="hueSlideValue" @change="hueSlide"></slider>
				</div>
				<div v-if="alpha" class="opacity">
					<slider :style="opacityStyle" :value="opacitySlideValue" @change="opacitySlide"></slider>
				</div>
			</div>
		</div>
		<div class="color-value">
			<template>
				<div class="type-ul type-hex" v-show="isHex">
					<ul>
						<li>
							<input v-model="origin.hex" @change="change">
							<p>{{ colorType.toUpperCase() }}</p>
						</li>
					</ul>
				</div>
			</template>
			<template>
				<div class="type-ul type-rgb" v-show="isRgb">
					<ul>
						<li>
							<input v-model.number="origin.rgb.r" type="number" @change="change">
							<p>R</p>
						</li>
						<li>
							<input v-model.number="origin.rgb.g" type="number" @change="change">
							<p>G</p>
						</li>
						<li>
							<input v-model.number="origin.rgb.b" type="number" @change="change">
							<p>B</p>
						</li>
						<li v-if="alpha">
							<input v-model.number="origin.alpha" type="number" step="0.01" @change="change">
							<p>A</p>
						</li>
					</ul>
				</div>
			</template>
			<a v-if="colorModes.length > 1" href="javascript:;" class="color-toggle" @click="toggleMode"></a>
		</div>
	</div>
</template>

<script>
	import { getTinyColor, getElSizePosition, getValInRange, throttle } from './utility';
	import Slider                                                       from './slider';

	export default {
		components : {
			Slider
		},
		props      : {
			value : {
				type    : String,
				default : '#f00'
			},
			/**
			 * Режим отображения значения
			 */
			mode : {
				type    : [String, Array],
				default : function () {
					return ['hex', 'rgb'];
				}
			},
			/**
			 * Прозрачность
			 */
			alpha : {
				type    : Boolean,
				default : true
			}
		},
		data() {
			const origin      = getTinyColor(this.value);
			const { h, s, v } = origin.hsv;
			const colorModes  = Array.isArray(this.mode) ? this.mode : [this.mode];
			return {
				colorModeIdx : 0,
				colorModes   : colorModes,
				sizeInfo     : {},
				// color value in data logic
				color : {
					hue        : h,
					saturation : s,
					value      : v
				},
				// origin calculated by tinycolor
				origin
			};
		},
		computed : {
			/**
			 * Возвращает rgb/rgba значение текущего цвета
			 * @returns {string}
			 */
			rgbString() {
				const { alpha, rgb : { r, g, b } } = this.origin;
				return this.alpha ? `rgba(${r}, ${g}, ${b}, ${alpha})` : `rgb(${r}, ${g}, ${b})`;
			},
			/**
			 * Возвращает hex значение текущего цвета. Если есть прозрачность, то будет возвращаться 8 символов,
			 * вместо 6 - новый формат
			 *
			 * @returns {*}
			 */
			hexString() {
				return this.origin.hex;
			},
			/**
			 * Оттенок текущегог цвета. Для отображения цвета основной области выбора
			 *
			 * @returns {*}
			 */
			hueString() {
				const hue     = this.color.hue;
				const { hex } = getTinyColor(`hsv(${hue}, 100%, 100%)`);
				return hex;
			},
			/**
			 * Значение оттенка цвета в слайдере
			 *
			 * @returns {number}
			 */
			hueSlideValue() {
				const hue = this.color.hue;
				return Math.round(hue / 360 * 100);
			},
			/**
			 * Значение прозрачности в слайдере
			 *
			 * @returns {number}
			 */
			opacitySlideValue() {
				return this.origin.alpha * 100;
			},
			/**
			 * Координаты указателя
			 *
			 * @returns {{top: string, left: string}}
			 */
			pointerPosition() {
				const { saturation, value } = this.color;
				const left                  = `${saturation}%`;
				const top                   = `${100 - value}%`;
				return {
					left,
					top
				};
			},
			/**
			 * Стиль слайдера с прозрачностью
			 *
			 * @returns {{background: string}}
			 */
			opacityStyle() {
				return {
					background : `linear-gradient(to right, transparent 0%, ${this.rgbString} 100%)`
				};
			},
			/**
			 * Текущий тип цвета (hex/rgb)
			 * @returns {*}
			 */
			colorType() {
				return this.colorModes[this.colorModeIdx];
			},
			/**
			 * Является HEX
			 * @returns {boolean}
			 */
			isHex() {
				return this.colorType === 'hex';
			},
			/**
			 * Является RGB
			 * @returns {boolean}
			 */
			isRgb() {
				return this.colorType === 'rgb';
			},
			/**
			 * Является HSV
			 * @returns {boolean}
			 */
			isHsv() {
				return this.colorType === 'hsv';
			}
		},
		watch    : {
			value(val) {
				this.resetOrigin(val);
				if (!this.isDragging) {
					this.resetColor();
				}
			}
		},
		methods  : {
			/**
			 * Сбрасывает значение основной переменной
			 *
			 * @param val
			 */
			resetOrigin(val) {
				this.origin = getTinyColor(val);
			},
			/**
			 * Сброс и синхронизация переменной color
			 */
			resetColor() {
				const { h, s, v }     = this.origin.hsv;
				this.color.hue        = h;
				this.color.saturation = s;
				this.color.value      = v;
			},
			/**
			 * Действия при изменении
			 */
			change() {
				this.resetOrigin(this[`${this.colorType}String`]);
				this.resetColor();
				this.emitChange();
			},
			/**
			 * Переключение режимов
			 */
			toggleMode() {
				this.colorModeIdx++;
				if (this.colorModeIdx >= this.colorModes.length) {
					this.colorModeIdx = 0;
				}

				this.emitChange();
			},
			/**
			 * Проброс изменения дальше
			 */
			emitChange() {
				this.$nextTick(() => {
					const type = this.colorType;
					this.$emit('input', this[`${type}String`]);
					this.$emit('change', this[`${type}String`]);
				});
			},
			/**
			 * Действия при изменении оттенка на основном слайдере
			 *
			 * @param val
			 */
			hueSlide(val) {
				const h                       = Math.round(val / 100 * 360);
				let { alpha, hsv : { s, v } } = this.origin;
				if (!this.alpha) {
					alpha = 1;
				}

				const color    = getTinyColor(`hsva(${h}, ${s}%, ${v}%, ${alpha})`);
				this.color.hue = h;
				this.origin    = color;
				this.emitChange();
			},
			/**
			 * Действия при изменении прозрачности на слайдере прозрачности
			 *
			 * @param val
			 */
			opacitySlide(val) {
				this.origin.alpha                  = Math.round(val) / 100;
				const { alpha, hsv : { h, s, v } } = this.origin;

				this.origin = getTinyColor(`hsva(${h}, ${s}%, ${v}%, ${alpha})`);
				this.emitChange();
			},
			/**
			 * Нажатие мышкой на основную область выбора цвета
			 *
			 * @param e
			 */
			choose(e) {
				this.onDragStart(e);
			},
			/**
			 * @param e
			 */
			onDragStart(e) {
				this.isDragging = true;
				this.sizeInfo   = getElSizePosition(this.$refs.vcolors);
				this.onDragging(e);
				this.registerGlobalEvent('bind');
			},
			/**
			 * @param e
			 */
			onDragging(e) {
				if (!this.isDragging) {
					return;
				}
				const { pageX, pageY }             = e;
				const { width, height, left, top } = this.sizeInfo;

				const l = pageX - left - window.scrollX;
				const t = pageY - top - window.scrollY;

				let { alpha } = this.origin; // opacity
				if (!this.alpha) {
					alpha = 1;
				}
				let saturation = l / width * 100; // x axis represents saturation
				let value      = (height - t) / height * 100; // y axis represent value
				// ATTENTION: keep hue's value
				const hue  = this.color.hue;
				saturation = Math.round(getValInRange(saturation, 0, 100));
				value      = Math.round(getValInRange(value, 0, 100));

				this.color.saturation = saturation;
				this.color.value      = value;

				this.origin = getTinyColor(`hsva(${hue}, ${saturation}%, ${value}%, ${alpha})`);
				this.emitChange();
			},
			/**
			 * Отпускание клавиши мыши
			 */
			onDragEnd() {
				this.isDragging = false;
				this.registerGlobalEvent('unbind');
			},
			/**
			 * WHY 17 => for one keyframe duration in 60FPS
			 * @return {[type]} [description]
			 */
			registerGlobalEvent(type) {
				type = type === 'bind' ? 'addEventListener' : 'removeEventListener';
				window[type]('mousemove', throttle(this.onDragging, 16.7));
				window[type]('mouseup', this.onDragEnd);
				window[type]('contextmenu', this.onDragEnd);
			}
		}
	};
</script>

<style lang="scss">
	.colorpanel {
		width: 256px;
		background-color: #fff;
		box-shadow: 0 0 5px 0 rgba(20, 20, 20, .2);
		border-radius: 3px;
		overflow: hidden;

		*,
		*:before,
		*:after {
			box-sizing: border-box;
		}

		ul, li {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		.color-pointer {
			width: 16px;
			height: 16px;
			border-radius: 8px;
			position: absolute;
			left: 0;
			top: 0;
			box-shadow: 0 0 5px 0 rgba(0, 0, 0, .5);
			border: 1px solid #fff;
			z-index: 1;
			cursor: pointer;
			transform: translateX(-8px) translateY(-8px);
		}

		.colors {
			width: 256px;
			height: 160px;
			position: relative;
			background-color: #f30;
			overflow: hidden;

			& > div {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}

			.brightness-light {
				background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0))
			}

			.brightness-dark {
				background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000)
			}
		}

		.color-present {
			width: 40px;
			height: 40px;
			border-radius: 20px;
			position: relative;
			overflow: hidden;
			border: 1px solid #eee;
			position: relative;
			z-index: 0;

			&:after {
				content: '';
				position: absolute;
				z-index: -1;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMElEQVQ4T2N89uzZfwY8QFJSEp80A+OoAcMiDP7//483HTx//hx/Ohg1gIFx6IcBALl+VXknOCvFAAAAAElFTkSuQmCC);
			}

			div {
				height: 100%;
				width: 100%;
			}
		}

		.color-control {
			display: flex;
			justify-content: space-between;
			padding: 15px;
			width: 100%;

			.hue {
				height: 12px;
				width: 100%;
				border-radius: 3px;
				background: linear-gradient(to right, #f00 0%, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, #f00 100%);
			}

			.opacity {
				height: 12px;
				width: 100%;
				border-radius: 3px;
				box-shadow: 0 0 1px 0 rgba(0, 0, 0, .2);
				position: relative;
				z-index: 0;

				&:after {
					content: '';
					position: absolute;
					z-index: -1;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMElEQVQ4T2N89uzZfwY8QFJSEp80A+OoAcMiDP7//483HTx//hx/Ohg1gIFx6IcBALl+VXknOCvFAAAAAElFTkSuQmCC);
				}
			}

			.color-slider {
				width: 170px;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
			}
		}

		input[type=number]::-webkit-inner-spin-button,
		input[type=number]::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		.color-value {
			text-align: center;
			padding: 0 10px 5px;
			border-bottom: 1px solid #eee;
			display: flex;
			justify-content: space-between;
			font-size: 14px;

			input {
				text-align: center;
				padding: 2px 0;
				border: 1px solid #eee;
				width: 46px;
				outline: none;
				border-radius: 3px;
				transition: border-color .2s ease;
				line-height: 1;
				font-size: 12px;

				&:hover {
					border-color: #2196f3;
				}
			}
		}

		.type-ul {
			width: 200px;

			ul {
				display: flex;
				justify-content: space-between;

				p {
					color: #999;
					margin: 5px 0;
					line-height: 1;
					font-size: 13px;
				}
			}
		}

		.type-hex {
			ul {
				justify-content: center;
			}

			input {
				width: 120px;
			}
		}

		.color-toggle {
			position: relative;
			display: block;
			width: 20px;
			height: 24px;
			border-radius: 3px;
			transition: background-color .2s ease;

			&:hover {
				background-color: #eee;
			}

			&:before,
			&:after {
				position: absolute;
				content: '';
				width: 5px;
				height: 5px;
				top: 10px;
			}

			&:before {
				left: 5px;
				border-bottom: 1px solid #999;
				border-left: 1px solid #999;
				transform: rotate(45deg);
			}

			&:after {
				right: 5px;
				border-top: 1px solid #999;
				border-right: 1px solid #999;
				transform: rotate(45deg);
			}
		}
	}
</style>