<template>
	<div class="login-window">
		<b-container>
			<div class="text-center logo">
				<img class="logo-image" alt="HWM ticket system logo" src="@/assets/images/logo.svg" @click="showReferrer">
				<p class="text-center small text-muted">Валидатор</p>
				<p v-if="referrerVisible" class="referrer text-center small text-muted">{{ referrer }}</p>
			</div>

			<b-form @submit="submitLogin" class="login-form">
				<b-form-group :disabled="loggingIn">
					<b-form-group
						id="form-login"
						label="Введите логин"
						label-for="form-login-input"
						:state="validateLogin">
						<b-form-input id="form-login-input"
						              :disabled="loggingIn"
						              :state="validateLogin"
						              v-model.trim="form.username"></b-form-input>
					</b-form-group>
					<b-form-group
						id="form-password"
						label="Введите пароль"
						label-for="form-login-password"
						:state="validatePassword">
						<b-form-input type="password"
						              :disabled="loggingIn"
						              id="form-login-password"
						              :state="validatePassword"
						              v-model.trim="form.password"></b-form-input>
					</b-form-group>
					<b-form-group>
						<b-button type="submit" variant="primary" class="submit-button" :disabled="loggingIn">
							<span v-if="loggingIn">Проверка...</span>
							<span v-else>Войти</span>
						</b-button>
					</b-form-group>
				</b-form-group>

				<b-alert
					variant="danger"
					dismissable
					:show="!!error"
					@dismissed="clearError()">
					<h4 class="alert-heading">Ошибка</h4>
					{{ error }}
				</b-alert>
			</b-form>

		</b-container>
	</div>
</template>

<script>

	import { mapGetters } from 'vuex';

	export default {
		name : 'Login',
		data() {
			return {
				loggingIn       : false,
				form            : {
					username : '',
					password : ''
				},
				error           : null,
				instanceName    : '...',
				tsVersion       : '...',
				referrerVisible : false
			};
		},
		mounted() {
			// this.checkBackend();
		},

		computed : {
			...mapGetters([
				'isLoggedIn'
			]),

			validateLogin() {
				return this.form.username.length > 1;
			},
			validatePassword() {
				return this.form.password.length > 1;
			},
			referrer() {
				return document.referrer;
			}
		},

		methods : {
			/**
			 * Попытка авторизоваться
			 *
			 * @param event
			 */
			async submitLogin(event) {
				event.preventDefault();

				this.clearError();
				this.$set(this, 'loggingIn', true);
				this.$forceUpdate();

				try {
					const loginResult = await this.$store.dispatch('login', this.form);
					console.log('Login OK: ', loginResult);

					this.$router.push({ name : 'Main' });
				}
				catch (e) {
					console.log(e);
					console.error('Логин не удался: ', e, e.data);
					try {
						this.setError(e.response.data.error);
					}
					catch (setErrorError) {
						try {
							this.setError(e.response.data.message);
						}
						catch (finalError) {
							this.setError('Ошибка сети: ' + JSON.stringify(finalError));
						}
					}
				}
        finally {
          this.$set(this, 'loggingIn', false);
        }
			},

			/**
			 * Установка ошибки
			 *
			 * @param message
			 */
			setError(message) {
				this.$set(this, 'error', message);
			},

			/**
			 * Очистка ошибки
			 */
			clearError() {
				this.$set(this, 'error', null);
			},

			async checkBackend() {
				try {
					const result = await this.$API.common.checkBackend();
					console.log('RESULT', result);
					this.instanceName = result.instance;
					this.tsVersion    = result.ts_version;
				}
				catch (e) {
					console.warn('Backend check failed', e);
				}
			},

			showReferrer() {
				if (this.form.username === 'debug') {
					this.referrerVisible = true;
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.login-window {
    background-color: #FFFFFF;
    height: 100vh;
    width: 100vw;

		.logo {
			img {
				width: 396px;
				padding: 30px;
				max-width: 100%;
			}
		}

		.login-form {
			max-width: 400px;
			margin-left: auto;
			margin-right: auto;

			.submit-button {
				width: 100%;
			}
		}

		.alert-heading {
			font-weight: 700;
		}
	}
</style>
