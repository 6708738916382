import lodash from 'lodash';

export default {
	install(Vue) {
		window._ = lodash;
		Vue._    = lodash;
		Object.defineProperties(Vue.prototype, {
			_ : {
				get() {
					return lodash;
				}
			}
		});
	}
};
