/**
 * Подключение UI и глобальных стилей
 */
import '@/styles/import.scss';

/**
 * Импорт главного представления
 */
import App from './App.vue';
/**
 * Импорт системных компонентов
 */
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import VueMoment from 'vue-moment';
import VueRouter from 'vue-router';
import 'moment/dist/locale/ru';

/**
 * Импорт системных компонентов
 */
import Api from './api';
import store from './store';
import router from './router';

/**
 * Глобальные директивы
 */
import '@/common/directives';
/**
 * Импорт глобальных фильтров
 */
import * as filters from '@/common/filters';
/**
 * Регистрация шины событий
 */
import GlobalBus from '@/common/components/GlobalBus';
/**
 * Импорт вспомогательных функций
 */
import VueLodash from '@/common/components/Lodash';

/**
 * Регистрация Date Picker
 */
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
import 'vue2-datepicker/locale/en';
// Font awesome
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

DatePicker.props.titleFormat.default = 'DD.MM.YYYY';
DatePicker.props.timeTitleFormat.default = 'DD.MM.YYYY';
DatePicker.props.valueType.default = 'YYYY-MM-DD';
DatePicker.props.format.default = 'DD.MM.YYYY';
DatePicker.props.editable.default = false;
DatePicker.props.inputClass.default = 'form-control';
DatePicker.props.lang.default = () => {
  return {monthFormat: 'MMMM'};
};
Vue.component('date-picker', DatePicker);

library.add(fas);
library.add(far);
Vue.component('font-awesome-icon', FontAwesomeIcon);

/**
 * Регистрация библиотеки для работы с датой и временем
 */
momentDurationFormatSetup(moment);
moment.locale('ru');
Vue.use(VueMoment, {moment});
/**
 * Регистрация глобальных фильтров
 */
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});
/**
 * Регистрация сторонних компонентов
 */
Vue.use(BootstrapVue);
/**
 * Регистрация системных компонентов
 */
Vue.use(Api);
Vue.use(VueLodash);
Vue.use(GlobalBus);
Vue.use(VueRouter);

// Переменная сигнализирующая, что приложение инициализировалось
window.hwmAppLoaded = false;

store.dispatch('auth')
  .finally(() => {
    /**
     * Регистрация приложения
     */
    new Vue({
      el        : '#validator-client',
      store,
      router,
      template  : '<App/>',
      components: {App},
      mounted   : function () {
        this.$nextTick(function () {
          window.hwmAppLoaded = true;
        });
      }
    });
  });
