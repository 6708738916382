<template>
	<div id="main-screen">
		<!-- Основной экран -->
		<div class="logged-in" :class="validityClass">

			<qr-scanner class="qr-scanner" v-if="!cameraWithBarcode && isQrCodeScannerActive" @result="onQrScan"/>
			<html5-qr-code class="qr-scanner" v-if="cameraWithBarcode && isQrCodeScannerActive" @result="onQrScan"/>

			<div class="shadow"></div>

			<div class="screen-frame left" :class="validityClass"></div>
			<div class="screen-frame top" :class="validityClass"></div>
			<div class="screen-frame bottom" :class="validityClass"></div>
			<div class="screen-frame right" :class="validityClass"></div>

			<div class="camera-top-button" @click="activateCamera" v-if="hasCamera" title="Включить/выключить камеру">
				<b-icon-camera/>
			</div>

			<div class="text-contents d-flex justify-content-around flex-column">
				<b-form @submit="submitValidateForm" class="ticket-number-input-form" :class="showInputClass">
					<b-input v-model="ticketNumber"
					         id="ticket-number-input"
					         autocomplete="new-password"
					         :name="ticketNumber"></b-input>
				</b-form>
				<div class="ticket-information" v-if="scannedAnything">
					<b-badge class="check-badge" v-if="ticketInfo.validating">Идет проверка...</b-badge>

					<div v-else>
						<div v-if="ticketInfo.found" class="text-container-found">
              <div class="ticket-info-main-part">
								<div class="is-valid" v-if="ticketInfo.valid">
									<div class="docs_check_required"
									     v-if="docsCheckRequired == 1">Требуется проверка документов
									</div>
									<div v-else class="please-go">Проходите</div>
								</div>
								<div class="is-invalid" v-else>
									<div class="invalid-label">Бейдж<br><br><b>НЕДЕЙСТВИТЕЛЕН</b></div>
									<div class="invalid-reason">
										<div v-for="reason in ticketInfo.data.invalid_reason" :key="reason">
											<template v-if="reason.message === 'badgeNotValid'">
                        Даты действия бейджа с {{ reason.data.dateStart | moment('DD.MM.YYYY HH:mm') }}
                        по {{ reason.data.dateEnd | moment('DD.MM.YYYY HH:mm') }}. Текущая дата и время:
                        {{ new Date() | moment('DD.MM.YYYY HH:mm') }}.
                      </template>
                      <template v-else>{{ reason.message }}</template>
										</div>
									</div>
								</div>

								<div class="number">{{ ticketInfo.data.number }}</div>
								<div class="ticket_datetime">
									<div class="ticket_date"
									     v-if="ticketInfo.data.date_start">{{ ticketInfo.data.date_start | moment('DD.MM.YYYY HH:mm') }}
                    - {{ ticketInfo.data.date_end | moment('DD.MM.YYYY HH:mm') }}
									</div>
								</div>

                <div v-if="ticketInfo.data.owner_name || hasPersons || hasPersonsPhoto"
                     class="ticket-info-secondary-part">
                  <hr>
                  <div class="category-type"><b>{{ ticketInfo.data.category_type }}:</b></div>
                  <div class="owner_name"
                       v-if="ticketInfo.data.owner_name">{{ ticketInfo.data.owner_name }}
                  </div>
                  <div class="owner_organization"
                       v-if="ticketInfo.data.owner_organization">{{ ticketInfo.data.owner_organization }}
                  </div>
                </div>

								<div class="category" v-if="ticketInfo.data.category">
                  <hr>
                  <div class="category-block-title"><b>Категория:</b></div>
									<span v-if="ticketInfo.data.category_color"
									      :style="{'background-color': ticketInfo.data.category_color, color: 'white', display: 'inline-block', padding: '5px 15px'}">{{ ticketInfo.data.category }}</span>
									<span v-else>{{ ticketInfo.data.category }}</span>
								</div>

							</div>

						</div>
						<div v-else class="text-container-not-found">
							<div class="error-text"
							     v-if="ticketInfo.errorCode === 400">Такого бейджа не существует
							</div>
							<div v-else-if="ticketInfo.errorCode === 403">Ошибка при проверке бейджа: ошибка доступа к серверу, обратитесь к сотруднику.</div>
							<div v-else-if="ticketInfo.errorCode === 500">Ошибка 500 на сервере при проверке бейджа, обратитесь к сотруднику.</div>
							<div v-else>Ошибка при проверке бейджа: {{ ticketInfo.errorCode }}, обратитесь к сотруднику.</div>
							<div class="input-number">{{ lastTicketNumber }}</div>
						</div>

					</div>
				</div>

				<div class="show-ticket d-flex justify-content-center" v-else>
					<div class="align-self-center show-ticket-text">
						<div>
							Пожалуйста, нажмите значок камеры и сканируйте QR-код
						</div>
						<div class="font-weight-bold" v-html="currentDateTime"></div>
					</div>
				</div>

				<div class="validator-info top" v-if="loggedInData.id">
					<span class="number-of-tickets" v-if="loggedInData.validation_stat_enabled && ticketInfo.data">
						<span>{{ ticketInfo.data.title }}</span>
						/
						<span>Прошло: {{
								ticketInfo.data.number_of_validated_tickets
									? ticketInfo.data.number_of_validated_tickets : 0
							}}</span>
						/
						<span>Не прошло: {{
								ticketInfo.data.number_of_non_validated_tickets
									? ticketInfo.data.number_of_non_validated_tickets : 0
							}}</span>
						/
						<span>Всего: {{
								ticketInfo.data.number_of_tickets
									? ticketInfo.data.number_of_tickets : 0
							}}</span>
						</span>
				</div>
<!--				<div class="validator-info" v-if="loggedInData.id">-->
<!--					<span class="description" v-if="loggedInData.description">{{ loggedInData.description }}</span>-->
<!--					<span class="location" v-if="validatorLocationTitle || validatorHallTitle">-->
<!--						<span v-if="validatorLocationTitle">{{ validatorLocationTitle }}<span v-if="validatorHallTitle"> / </span></span>-->
<!--						<span v-if="validatorHallTitle">{{ validatorHallTitle }}</span>-->
<!--					</span>-->
<!--					<span class="mode">-->
<!--						<span v-if="loggedInData.readonly == 1">Информационный режим</span>-->
<!--						<span v-else>Режим гашения бейджов</span>-->
<!--						/-->
<!--						<span v-if="loggedInData.group_tickets_scan_mode === 'each'">Групповой режим: по одному</span>-->
<!--						<span v-else>Групповой режим: один для всех</span>-->
<!--					</span>-->
<!--				</div>-->


			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters }  from 'vuex';
	import QrScanner       from '@/components/QrScanner';
	import Html5QrCode     from '@/components/Html5QrCode.vue';
	import QrScannerLib    from 'qr-scanner';
	import { BIconCamera } from 'bootstrap-vue';

	export default {
		name       : 'Main',
		components : { QrScanner, BIconCamera, Html5QrCode },
		data() {
			return {
				error                 : null,
				pollInterval          : null,
				pingSession           : null,
				scannedAnything       : false,
				ticketNumber          : '',
				lastTicketNumber      : '',
				hasCamera             : false,
				ticketInfo            : {
					valid : false,
					found : false,
					data  : null
				},
				authChecked           : false,
				resetTimer            : null,
				currentDateTime       : this.$moment().format('L<br>HH:mm:ss'),
				personsPhoto          : {},
				isQrCodeScannerActive : false,
				inputSelector         : '#ticket-number-input'
			};
		},

		computed : {
			...mapGetters([
				'loggedInData',
				'user',
				'token',
				'isLoggedIn',
				'validatorLocationTitle',
				'validatorHallTitle',
				'userSelectedLocationId',
				'userSelectedHallId'
			]),

			cameraWithBarcode() {
				return !!this.loggedInData.camera_enable_barcode;
			},

			validityClass() {
				if (this.ticketInfo.validating) {
					return '';
				}

				let ret = [];

				if (this.scannedAnything) {
					if (this.ticketInfo.found) {
						ret.push('found');
					}
					else {
						ret.push('not-found');
					}
					if (this.ticketInfo.valid) {
						ret.push('valid');
					}
					else if (this.scannedAnything) {
						ret.push('invalid');
					}
				}
				else {
					return 'waiting';
				}

				return ret.join(' ');
			},

			showInputClass() {
				return (this.ticketNumber.length > 0 ? 'show-input' : 'hide-input');
			},

			hasPersons() {
				return this.ticketInfo.data.abonement_id && this.ticketInfo.data.categories && this.ticketInfo.data.categories.length;
			},

			hasPersonsPhoto() {
				return this.ticketInfo?.data?.owner_ids && this.ticketInfo?.data?.owner_ids.length;
			},

			docsCheckRequired() {
				if (this.ticketInfo.data.category_docs_check_required) {
					return true;
				}

				let result = false;
				if (this.hasPersons) {
					this._.each(this.ticketInfo.data.categories, val => {
						if (val.docs_check_required) {
							result = true;

							return false;
						}
					});
				}

				return result;
			}
		},
		created() {
			setInterval(() => {
				this.setCurrentDateTime();
			}, 500);
		},
		async mounted() {
			this.enableInputFocus();
			this.startPing();
			this.detectCamera();
		},
		methods : {
			onQrScan(decodedText, decodedResult) {
				console.log(decodedText);
				this.isQrCodeScannerActive = false;
				this.ticketNumber          = decodedText;

				this.validateTicket();

			},
			activateCamera() {
				this.ticketNumber          = '';
				this.isQrCodeScannerActive = !this.isQrCodeScannerActive;
			},

			async detectCamera() {
				this.hasCamera = await QrScannerLib.hasCamera();
			},

			setCurrentDateTime() {
				this.currentDateTime = this.$moment().format('L<br>HH:mm:ss');
			},
			/**
			 * Обработка ввода номера бейджа
			 * @param event
			 */
			submitValidateForm(event) {
				event.preventDefault();
				console.log('Validating...', this.ticketNumber);
				this.validateTicket();
			},
			/**
			 * Валидация бейджа
			 * @returns {Promise<void>}
			 */
			async validateTicket() {
				if (this.resetTimer) {
					window.clearTimeout(this.resetTimer);
					this.resetTimer = null;
				}

				let ticket;
				this.$set(this.ticketInfo, 'validating', true);
				this.$set(this, 'personsPhoto', {});

				if (await this.processSystemCommands()) {
					return;
				}

				this.lastTicketNumber = this.ticketNumber;
				this.ticketNumber     = '';
				this.inputBlur();

				try {
					ticket = await this.$store.dispatch('validateTicket', this.lastTicketNumber);
					this.$set(this.ticketInfo, 'found', true);
					this.$set(this.ticketInfo, 'data', ticket);
					this.$set(this.ticketInfo, 'valid', ticket.valid);
					//this.$set(this.ticketInfo, 'used', ticket.valid);
					console.warn('Validation successful', ticket);

					// Отправка информации о валидности
					if (ticket.valid) {
						this.$API.sound.playOk();
						// await this.$store.dispatch('setTicketUsed', ticket);
					}
					else {
						if (ticket.used) {
							this.$API.sound.playUsed();
						}
						else {
							this.$API.sound.playError();
						}
					}

				}
				catch (e) {
					this.$API.sound.playError();

					let errorCode = 'нет связи с сервером';
					try {
						if (e.response) {
							errorCode = e.response.status;
						}
						else {
							console.log('No response: ', JSON.stringify(e), e);
						}
					}
					catch (z) {
						errorCode += '' + z;
					}

					console.error('Validation failed', errorCode);
					this.$set(this.ticketInfo, 'found', false);
					this.$set(this.ticketInfo, 'valid', false);
					this.$set(this.ticketInfo, 'data', null);
					this.$set(this.ticketInfo, 'errorCode', errorCode);
					this.$set(this.ticketInfo, 'errorData', e);
				}
				this.$set(this.ticketInfo, 'validating', false);

				this.$set(this, 'scannedAnything', true);

				this.resetTimer = window.setTimeout(() => {
					this.resetState();
				}, 15000);
			},
			/**
			 * Обработка системных команд
			 */
			async processSystemCommands() {
				let result = false;
				switch (this.ticketNumber) {
					case '/ok':
						this.$set(this.ticketInfo, 'validating', false);
						this.$API.sound.playOk();
						result = true;

						break;
					case '/used':
						this.$set(this.ticketInfo, 'validating', false);
						this.$API.sound.playUsed();
						result = true;

						break;
					case '/error':
						this.$set(this.ticketInfo, 'validating', false);
						this.$API.sound.playError();
						result = true;

						break;
					case '/logout':
						this.logout();
						result = true;

						break;

					case '/settings':
						this.$router.push({ name : 'SelectLocation' });
						result = true;

						break;
				}

				return result;
			},
			/**
			 * Включение input focus
			 */
			enableInputFocus() {
				let querySelector = this.inputSelector;
				window.onkeydown  = function () {
					console.log('Keydown');
					let e = document.querySelector(querySelector);
					if (e && typeof e.focus === 'function') {
						e.focus();
					}
				};
			},
			startPing() {
				this.stopPing();

				if (!this.user || !this.token) {
					return;
				}

				this.pingSession = setInterval(() => {
					this.$API
					    .validator
					    .ping({ version : 'mobileValidator' })
					    .catch(error => {
						    if (!error || !error.response) {
							    return;
						    }

						    if (error.response.status === 401 || error.response.status === 403) {
							    this.stopPing();
						    }
					    });
				}, 60 * 1000);
			},
			stopPing() {
				if (this.pingSession) {
					clearInterval(this.pingSession);
				}
			},
			/**
			 * Отключение input focus
			 */
			disableInputFocus() {
				window.onkeydown = undefined;
			},

			/**
			 * Отключение input focus
			 */
			inputBlur() {
				let e = document.querySelector(this.inputSelector);
				if (e && typeof e.blur === 'function') {
					e.blur();
				}
			},
			/**
			 * Сброс состояния
			 */
			resetState() {
				this.$set(this, 'scannedAnything', false);
				this.$set(this, 'personsPhoto', {});
			},
			/**
			 * Функция для множественных форм слов
			 */
			pluralize(n, titles) {
				return titles[(n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2)];
			},
			/**
			 * Форматирование даты
			 * @param d
			 * @returns {string}
			 */
			formatDate(d) {
				if (d) {
					return this.$moment(d).format('DD.MM.YYYY');
				}
				else {
					return '';
				}
			},
			/**
			 * Получить фото владельца
			 *
			 * @param id
			 * @returns {Promise<any>}
			 */
			getPersonPhoto(id) {
				if (this.personsPhoto.hasOwnProperty(id)) {
					return this.personsPhoto[id];
				}

				this.$set(this.personsPhoto, id, null);
				this.$API
				    .validator
				    .getPersonPhoto(id)
				    .then(data => {
					    this.$set(this.personsPhoto, id, data);
				    })
				    .catch(() => {
					    this.$set(this.personsPhoto, id, null);
				    });

				return this.personsPhoto[id];
			},

			async logout() {
				await this.$store.dispatch('logout');
				window.location.reload();
				this.$forceUpdate();
			}
		}
	};
</script>

<style lang="scss">
	.logged-in {
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;

		&.valid {
			background-color: green
		}

		&.waiting {
			background-color: #4c8dff
		}

		&.invalid {
			background-color: red
		}

		.text-contents {
			height: 100%;
			position: relative;
			padding: $borderWidth;
			margin-left: 15px;
			margin-right: 15px;

			@media (max-width: $screen-md-max) {
				padding-left: calc($borderWidth / 2);
				padding-top: $borderWidth;
				padding-bottom: calc($borderWidth / 2);
				padding-right: calc($borderWidth / 2);
			}
			// Всегда $borderWidth, ибо верхний отступ теперь больше
		}

		.ticket-number-input-form {
			position: absolute;
			height: 100px;
			line-height: 100px;
			padding: 30px;
			bottom: 100px;
			left: 50%;
			margin-left: -200px;
			text-align: center;
			width: 400px;
			background: rgba(0, 0, 0, 0.9);
			border-radius: 15px;
			z-index: 99;
			pointer-events: none;

			&.hide-input {
				opacity: 0;
			}

			@media (max-width: $screen-md-max) {
				width: 100%;
				left: 0;
				margin-left: 0;
				bottom: 0;
				border-radius: 15px 15px 0 0;
			}

			input[type="text"] {
				opacity: 1;
				transition: opacity 0.3s ease;
				background: none;
				border: none;
				height: 100%;

				&, &:focus {
					box-shadow: none;
					outline: none !important;
				}

				color: white;
				font-size: 40px;

				&.hide-input {
					opacity: 0;
				}

				@media (max-width: $screen-md-max) {
					font-size: 20px;
				}
			}
		}

		.ticket-information {
			text-align: center;
			font-size: 22px;
			padding: 5px;
			color: white;
		}

		// Затеняющий блок
		.shadow {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			background: rgba(0, 0, 0, 0.7);
			min-height: 100%;
		}

		// Видео с превью
		#preview {
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			z-index: -1;
		}

		.text-contents {
			position: relative;
			z-index: 2;
		}

		// Рамка с цветом, отображающая статус прохода
		.screen-frame {
			position: fixed;
			z-index: 2;
			pointer-events: none;
			background-color: white;

			&.valid {
				background-color: green
			}

			&.invalid {
				background-color: red
			}

			&.waiting {
				background-color: #315daf;
			}


			&.left {
				top: 0;
				left: 0;
				height: 100%;
				width: $borderWidth;
				@media (max-width: $screen-md-max) {
					width: calc($borderWidth / 2);
				}
			}

			&.top {
				top: 0;
				left: 0;
				height: $borderWidth;
				width: 100%;
        @media (max-width: $screen-md-max) {
          height: $borderWidth + $borderWidth;
        }
			}

			&.right {
				top: 0;
				right: 0;
				height: 100%;
				width: $borderWidth;

				@media (max-width: $screen-md-max) {
					width: calc($borderWidth / 2);
				}
			}

			&.bottom {
				left: 0;
				bottom: 0;
				height: $borderWidth;
				width: 100%;

				@media (max-width: 406px) {
					height: $borderWidth + 10px;
				}
			}
		}

		// Кнопка камеры сверху
		.camera-top-button {
			font-size: $borderWidth - 8px;
			line-height: 1;
			color: white;
			position: fixed;
			top: 0;
			right: 0;
			width: $borderWidth;
			height: $borderWidth;
			padding: 4px;
			z-index: 3;
			cursor: pointer;

      @media (max-width: $screen-md-max) {
        font-size: $borderWidth + $borderWidth - 20px;
        right: $borderWidth + $borderWidth - 20px;

        &:after {
          content: "Сканировать";
          position: fixed;
          width: 80px;
          font-size: 13px;
          top: 77px;
        }
      }
		}

		.text-container-not-found {
			height: 200px;
			position: absolute;
			top: 50%;
			margin-top: -100px;
			width: 100%;
			left: 0;
			padding: $borderWidth + 10px;

			@media (max-width: $screen-md-max) {
				padding: calc($borderWidth / 2) + 10px;
			}

			.error-text {
				font-size: 20px;
				font-weight: bold;

				@media (max-width: $screen-md-max) {
					font-size: 16px;
					line-height: 1.2;
					margin-bottom: 30px;
				}
			}
		}

		.text-container-found {

			@media (max-height: 900px) and (min-aspect-ratio: 1/1) {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;

				.ticket-info-main-part {
					flex-grow: 1;
					margin-right: 15px;
				}
				.ticket-info-secondary-part {
					flex-grow: 1;
					margin-left: 15px;
					padding-top: $borderWidth;
					@media (max-width: $screen-md-max) {
						padding-top: #{calc($borderWidth / 2)};
					}
				}
			}

			.persons-photo-img {
				max-width: 120px;
				max-height: 160px;
			}

			.docs_check_required {
				color: #ff6326;
				font-weight: bold;
				margin-top: 15px;

				@media (max-width: $screen-md-max) {
					font-size: 16px;
					line-height: 1.2;
					margin-bottom: 30px;
				}
			}

      .is-valid, .is-invalid {
        margin-bottom: 100px;
      }

			.please-go {
				font-size: 72px;
				font-weight: bold;
				margin-top: 30px;
				margin-bottom: 30px;

				@media (max-width: $screen-md-max) {
					font-size: 40px;
					margin-top: 15px;
					margin-bottom: 15px;

				}
			}

			.event {
				margin-bottom: 15px;
				font-weight: bold;
				font-size: 24px;

				@media (max-width: $screen-md-max) {
					font-size: 18px;
					line-height: 1.2;
					margin-bottom: 10px;
				}

				@media (max-width: 350px) and (max-height: 540px) {
					font-size: 16px;
				}
			}

			.category-type, .owner_name, .owner_organization {
				margin-top: 5px;
				margin-bottom: 5px;
				@media (max-width: $screen-md-max) {
					margin-top: 5px;
				}

				&.not-specified {
					display: none;
				}

				@media (max-width: $screen-md-max) {
					line-height: 1.2;
					font-size: 16px;
				}

				@media (max-width: 350px) and (max-height: 540px) {
					font-size: 14px;
				}
			}

			.category {
				@media (max-width: $screen-md-max) {
					margin-top: 10px;
					line-height: 1;
					font-size: 16px;
				}
			}

			.person_count {
				margin-top: 30px;
				font-size: 50px;
				line-height: 1.3;
				@media (max-width: $screen-md-max) {
					font-size: 30px;
					margin-top: 10px;
				}
			}

			.person_count:before {
				content: "Кол-во человек: ";
				display: block;
				font-size: 24px;
				@media (max-width: $screen-md-max) {
					font-size: 18px;
					display: inline;
				}
			}


			.ticket_datetime {
				font-weight: bold;
				margin-top: 10px;
				margin-bottom: 10px;

				@media (max-width: 350px) and (max-height: 540px) {
					font-size: 14px;
					line-height: 1.2;
				}

				.ticket_date, .ticket_time {
					@media (max-width: $screen-md-max) {
						font-size: 14px;
						line-height: 1.2;
					}

					@media (max-width: 350px) and (max-height: 540px) {
						display: inline-block;
					}
				}
			}

			.hall, .location {
				font-size: 20px;
				@media (max-width: $screen-md-max) {
					font-size: 14px;
					line-height: 1.2;
				}

				@media (max-width: 350px) and (max-height: 540px) {
					font-size: 12px;
					line-height: 1.2;
				}
			}

			.number {
				margin-top: 10px;
				@media (max-width: $screen-md-max) {
					font-size: 14px;
					line-height: 1.2;
				}

				@media (max-width: 350px) and (max-height: 540px) {
					margin-top: 5px;
				}
			}

			.is-invalid {
				color: yellow;
				padding-top: 15px;
				padding-bottom: 15px;
				@media (max-width: $screen-md-max) {
					padding-top: 10px;
					padding-bottom: 0;
				}

				.invalid-label {
          font-size: 20px !important;
					color: white;

					@media (max-width: $screen-md-max) {
						margin-top: 0;
						max-width: 100%;
						font-size: 18px;
						line-height: 1;
						margin-bottom: 15px;
					}

					@media (max-width: 350px) and (max-height: 540px) {
						margin-bottom: 5px;
						font-size: 14px;
					}
				}

				.invalid-reason {
					font-size: 20px;
					line-height: 1;

					&:before {
						content: "Причина: ";
						font-weight: bold;
					}

					@media (max-width: $screen-md-max) {
						font-size: 26px;
					}

					@media (max-width: $screen-md-max) {
						margin-top: 15px;
						max-width: 100%;
						font-size: 14px;
						line-height: 1.2;
						margin-bottom: 15px;
					}

					@media (max-width: $screen-md-max) {
						margin-top: 15px;
						max-width: 100%;
						font-size: 14px;
						line-height: 1.2;
						margin-bottom: 15px;
					}

					@media (max-width: 350px) and (max-height: 540px) {
						margin-top: 5px;
						margin-bottom: 5px;
						font-size: 12px;
					}
				}

				@media (max-width: 350px) and (max-height: 540px) {
					padding-top: 5px;
				}
			}

			.persons {
				font-size: 12px;

				.person {
					margin-bottom: 10px;
				}

				@media (max-width: $screen-md-max) {
					font-size: 9px;
				}
			}
		}

		.check-badge {
			margin-top: 50vh;
		}

		.validator-info {
			pointer-events: none;
			position: absolute;
			bottom: 0;
			z-index: 10;
			color: #fff097;
			font-size: 12px;
			width: calc(100% - #{$borderWidth*2});
			text-align: center;
			padding-top: 15px;
			padding-bottom: 9px;

			&.top {
				top: 2px;
				bottom: auto;
			}

			.description {
				font-weight: bold;
			}

			.mode {
				margin: 0;
				display: block;
			}

			.description, .location, .mode {
				margin-left: 10px;
				margin-right: 10px;
			}

			@media (max-width: $screen-md-max) {
				bottom: 5px;
				font-size: 11px;
				line-height: 1.2;
				width: calc(100% - #{$borderWidth});
				padding: 0;

				.description, .location, .mode {
					margin: 0;
					display: block;
				}

				.description {
					margin-bottom: 3px;
				}
				.location, .mode {
					font-size: 10px;
				}
			}
		}

		.show-ticket {
			width: 100%;
			height: 100%;
			text-align: center;
			z-index: 100;

			.show-ticket-text {
				font-size: 20px;
				color: white;
				padding: 10px;
			}
		}

		.person-count {
			@media (max-width: 350px) and (max-height: 540px) {
				font-size: 0.7em;
				line-height: 1.1;
			}
		}

		.formatted-seat-title {
			@media (max-width: 350px) and (max-height: 540px) {
				font-size: 0.8em;
			}
		}

		.pk-flag {
			@media (max-width: 350px) and (max-height: 540px) {
				font-size: 0.7em;
			}

			.pk-flag-title {
				display: inline-block;
			}

			.pushkin-card-icon {
				display: inline-block;
				width: 1.1em;
				height: 1.1em;
			}
		}
	}
</style>
