<template>
	<div class="html5-qrcode-component">
		<div id="html5-qrcode-reader"></div>
	</div>
</template>

<script>
	import { Html5Qrcode, Html5QrcodeSupportedFormats } from 'html5-qrcode';

	let html5QrCode;

	export default {
		name : 'Html5QrCode',
		mounted() {
			html5QrCode       = new Html5Qrcode('html5-qrcode-reader');
			let qrboxFunction = function (viewfinderWidth, viewfinderHeight) {
				let minEdgePercentage = 0.7; // 70%
				let minEdgeSize       = Math.min(viewfinderWidth, viewfinderHeight);
				let qrboxSize         = Math.floor(minEdgeSize * minEdgePercentage);
				return {
					width  : qrboxSize,
					height : qrboxSize
				};
			};

			const config = {
				fps                           : 30,
				huita: false,
				useBarCodeDetectorIfSupported : true,
				qrbox                         : qrboxFunction,
				formatsToSupport              : [
					Html5QrcodeSupportedFormats.QR_CODE,
					Html5QrcodeSupportedFormats.CODE_128,
					Html5QrcodeSupportedFormats.EAN_13
				]
			};

			html5QrCode.start({ facingMode : 'environment' }, config, this.onScanSuccess, undefined);
			console.log('Html5QrcodeScanner instance created and mounted');

		},
		methods : {
			onScanSuccess(decodedText, decodedResult) {
				console.log('Html5QrcodeScanner onScanSuccess', { decodedText, decodedResult });
				this.$emit('result', decodedText);
			}
		},
		async beforeDestroy() {
			await html5QrCode.stop();
			html5QrCode = null;
			console.log('Html5QrcodeScanner instance destroyed');
		}
	};
</script>

<style scoped lang="scss">
	#html5-qrcode-reader {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		align-items: center;
		overflow: hidden;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: absolute !important;
		z-index: 2;

		video {
			min-width: 100%;
			min-height: 100%;
		}
	}
</style>