<template>
	<div class="colorpicker" ref="colorpicker">
		<div class="color-block" @click="toggle">
			<div class="color-block-layer value" :style="{backgroundColor: color}"></div>
			<div class="color-block-layer bg"></div>
		</div>
		<color-panel v-show="show"
		             v-model="color"
		             :style="panelStyle"
		             :alpha="alpha"
		             :mode="mode"
		             @change="change"/>
	</div>
</template>

<script>
	import ColorPanel from './panel';

	export default {
		components : { ColorPanel },
		props      : {
			/**
			 * Значение (v-model)
			 */
			value : {
				type : String
			},
			/**
			 * Режим работы. От этого зависит, какое значение будет в value
			 */
			mode : {
				type    : [String, Array],
				default : function () {
					return ['hex', 'rgb'];
				}
			},
			/**
			 * Включить прозрачность
			 */
			alpha : {
				type    : Boolean,
				default : true
			},
			/**
			 * Позиция всплывающего окна
			 */
			position : {
				type    : [String, Object],  // up | down
				default : 'down'
			}
		},
		data() {
			return {
				show  : false,
				color : this.value
			};
		},
		computed : {
			panelStyle() {
				/**
				 * Стили для всплывающего окна
				 *
				 * @type {{}}
				 */
				let style = {};
				if (typeof this.position === 'string') {
					style.left = '48px';
					if (this.position === 'up') {
						style.bottom = 0;
					}
					else {
						style.top = 0;
					}
				}
				else {
					for (let key in this.position) {
						style[key] = this.position[key];
					}
				}
				return style;
			}
		},
		watch    : {
			value(newValue) {
				this.color = newValue;
			}
		},
		mounted() {
			// Скрытие панели если кликнули за её область
			document.addEventListener('click', event => {
				let isIn = this._includes(this.$refs.colorpicker, event.target);
				if (!isIn) {
					this.show = false;
				}
			});
		},
		methods : {
			/**
			 * Изменение значения
			 *
			 * @param color
			 * @param $instance
			 */
			change(color, $instance) {
				this.$emit('change', color);
				this.$emit('input', color);
			},
			/**
			 * Показать/скрыть панель
			 */
			toggle() {
				this.show = !this.show;
			},
			/**
			 * Проверка, куда клинули
			 *
			 * @param el
			 * @param child
			 * @returns {boolean|*}
			 * @private
			 */
			_includes(el, child) {
				if (el) {
					return el === child || el.contains(child);
				}
				else {
					return false;
				}
			}
		}

	};
</script>

<style lang="scss">
	.colorpicker {
		position: relative;
		display: inline-block;
		vertical-align: top;

		.colorpanel {
			position: absolute;
			z-index: 3;
		}
	}

	.color-block {
		width: 32px;
		height: 32px;
		cursor: pointer;
		position: relative;

		.color-block-layer {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}

		.bg {
			z-index: 0;
			background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMElEQVQ4T2N89uzZfwY8QFJSEp80A+OoAcMiDP7//483HTx//hx/Ohg1gIFx6IcBALl+VXknOCvFAAAAAElFTkSuQmCC);
		}

		.value {
			z-index: 1;
		}
	}
</style>