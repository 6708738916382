<template>
	<ul v-if="options && options.length"
	    class="cascader-menu">
		<li v-for="item in options"
		    @click="selectItem(item)"
		    class="cascader-menu-item"
		    :class="{
					'is-active': isActive(item),
					'is-selected': selectedValue === item[valueAttr],
				}">

			<div class="control-item custom-control b-custom-control-lg"
			     :class="{ 'custom-radio' : !multiple, 'custom-checkbox' : multiple }">
				<input v-model="emittedValue"
				       :type="!multiple ? 'radio' : 'checkbox'"
				       class="custom-control-input"
				       :value="item[valueAttr]"
				       @click.stop
				       :id="`item_${item[valueAttr]}`"/>
				<label class="custom-control-label"
				       @click.stop
				       :for="`item_${item[valueAttr]}`"/>
			</div>

			<slot v-bind="item">
				<span>{{ item[labelAttr] }}</span>
			</slot>
			<font-awesome-icon v-if="item[childrenAttr]"
			                   class="arrow-right"
			                   :icon="['fas', 'angle-right']"/>
		</li>
	</ul>
</template>

<script>
	export default {
		name   : 'CascaderMenu',
		inject : ['cascader'],
		props  : {
			value : [
				String,
				Number,
				Array
			],
			/**
			 * Список значений для меню
			 */
			options : Array,
			/**
			 * Мульти выбор
			 */
			multiple : {
				type    : Boolean,
				default : false
			},
			/**
			 * Атрибут для названия
			 */
			labelAttr : {
				type    : String,
				default : 'title'
			},
			/**
			 * Атрибут для значения
			 */
			valueAttr : {
				type    : String,
				default : 'id'
			},
			/**
			 * Атрибут для списка дочерних элементов
			 */
			childrenAttr : {
				type    : String,
				default : 'children'
			},
			/**
			 * ID активных элементов
			 */
			activeIds : Array,
			/**
			 * ID меню
			 */
			menuId : Number
		},

		data() {
			return {
				emittedValue  : null,
				selectedValue : null,
				firstSelected : []
			};
		},

		watch : {
			value(val, old) {
				if (
					val == old ||
					(val === null && old === []) ||
					(old === null && val === [])
				) {
					return;
				}

				this.emittedValue = val;
			},

			emittedValue(val) {
				this.$emit('input', val);
			}
		},

		mounted() {
			this.firstLoad();
		},

		methods : {
			firstLoad() {
				this.emittedValue = this.value;

				// При мультивыборе непонятно что открывать
				if (this.multiple) {
					return;
				}

				// Открываем всю цепочку меню до выбранного значения
				if (this.emittedValue && this.activeIds && this.activeIds.length) {
					this._.each(this.options, item => {
						if (this.activeIds.includes(item[this.valueAttr]) && item[this.valueAttr] !== this.emittedValue) {
							this.selectItem(item, false);

							return true;
						}
					});
				}
			},
			/**
			 * Активными являются текущее выбранное значение и все родители. Помечаем цветом
			 *
			 * @param item
			 * @returns {boolean|boolean}
			 */
			isActive(item) {
				return (this.activeIds && this.activeIds.includes(item.id));
			},

			/**
			 * Выбор элемента меню. Либо открываем если есть дочерние, либо выбираем само значение
			 * @param item
			 * @param emit
			 */
			selectItem(item, emit = true) {
				let children = item.children && item.children.length ? item.children : null;
				if (children) {
					this.cascader.addMenu(this.menuId, children);
					this.selectedValue = item[this.valueAttr];
				}
				else if (emit) {
					if (this.multiple) {
						let index = this.emittedValue.indexOf(item[this.valueAttr]);
						if(index >= 0) {
							this.emittedValue.splice(index, 1);
						}
						else {
							this.emittedValue.push(item[this.valueAttr]);
						}
					}
					else {
						this.$set(this, 'emittedValue', item[this.valueAttr]);
					}
				}
			}
		}
	};
</script>
