import * as common from './common';
import * as validator from './validator';
import * as sound from './sound';
import * as settings from './settings';
import * as ticketsList from './ticketsList';

export const API = {
  common,
  validator,
  sound,
  settings,
  ticketsList
};

export default {
  install(Vue) {
    Vue.$API = API;
    Object.defineProperties(Vue.prototype, {
      $API: {
        get() {
          return API;
        },
      },
    });
  },
};
