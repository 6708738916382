<template>
	<div class="qr-scanner-box">
		<video></video>
	</div>
</template>

<script>
	import QrScanner from 'qr-scanner';

	let qrScanner;
	export default {
		name : 'QrScanner',
		mounted() {
			console.log("QrScanner mounted and started");
			const videoElem = document.querySelector('.qr-scanner-box video');
			qrScanner = new QrScanner(
				videoElem,
				result => {
					console.log('scan result', result);
					qrScanner.stop();
					this.$emit('result', result.data);
				},
				{
					highlightScanRegion: true,
				}
			);

			qrScanner.start();
		},
		beforeDestroy() {
			qrScanner.stop();
			qrScanner.destroy();
			qrScanner = null;
			console.log("qrScanner instance destroyed");
		}
	};
</script>

<style lang="scss" scoped>
	.qr-scanner-box {
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		overflow: hidden;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: absolute;
		z-index: 2;
		video {
			min-width: 100%;
			min-height: 100%;
		}
	}
</style>