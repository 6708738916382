import * as API from '@/helpers/request';

/**
 * Запрос списка событий
 */
export async function getEvents(date, locationId, hallId) {
  return API.request.post('validator/tickets-list/events', {date, location_id: locationId, hall_id: hallId});
}

/**
 * Запрос расписания на событие
 */
export async function getSchedule(eventId, date = null) {
  return API.request.post('validator/tickets-list/schedule', {event_id: eventId, date: date});
}

/**
 * Запрос списка билетов на сеанс
 */
export async function getTickets(eventId, date, time) {
  return API.request.post('validator/tickets-list/tickets', {
    event_id: eventId,
    date,
    time
  });
}